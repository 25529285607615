import { css } from "@emotion/react";
import { LAYOUT_TYPES } from "constants/constants";
import GALANO_GROTESQUE from "assets/fonts/Galano_Grotesque/GalanoGrotesqueAltMedium.otf";

const pepsicoStyles = css`
  @font-face {
    font-family: "Galano Grotesque";
    src: local("Galano Grotesque"), url(${GALANO_GROTESQUE}) format("opentype");
  }

  &.pepsico-demo,
  &.pepsico {
    * {
      font-family: "Galano Grotesque" !important;
    }

    body {
      color: #090e35;
    }

    .browse-games-container {
      .game-name {
        color: #2c45ff;
      }
      .player-numb {
        .label-count {
          color: #2c45ff;
        }
        svg {
          fill: #2c45ff;
        }
      }
    }

    .leaderboard-popup-item .drag-handle:after,
    .achievement-popup .drag-handle:after,
    .tournament-info-popup .drag-handle:after,
    .custom-popup .drag-handle:after,
    .tournament-list-popup .drag-handle:after {
      background-color: #2c45ff !important;
    }

    .tournament-list-popup .close,
    .achievement-popup .close,
    .tournament-info-popup .close,
    .leaderboard-popup-item .close {
      fill: #ffffff !important;
    }
    .rank-list-container {
      background-color: #e3e9ff;
      padding-bottom: 0;
    }
    .rank-list-card {
      .list-heading {
        background-color: #e3e9ff;
      }
    }
    .top-featured-card {
      svg {
        filter: none !important;
      }
    }
    .tournament-info-popup {
      background: #2c45ff !important;
      .header-line {
        color: #ffffff !important;
      }
      .rank-list-container-first {
        .list-heading {
          color: #ffffff;
          .text-style {
            color: #ffffff;
          }
        }
      }
    }

    .scroll-bar {
      .list-group-item {
        background-color: #ffffff !important;
      }

      .rank,
      .end-time,
      .prize-amount {
        color: #000000 !important;
      }
    }

    .history-container {
      .header-style {
        color: #332927 !important;
      }

      .white-card {
        background-color: #e3e9ff !important;
        .tournament-name {
          .street-text {
            color: #000000 !important;
          }
          .street -text {
            color: #ff5b00 !important;
          }
        }
        .label-value {
          color: #000000 !important;
        }
      }
    }

    .user-profile-wrapper {
      .fontHeadStyle {
        color: #000000;
      }
    }

    .rank-list-card {
      .list-heading > * {
        color: #ffffff !important;
      }
    }

    .half-style {
      .name-style,
      .score,
      .label-style {
        color: #2c45ff !important;
      }
    }

    .leaderboard-popup-item {
      .modal_header {
        background-color: #ffffff;
        .gamename-style {
          color: #ffffff;
        }
      }

      .leaderboard-wrapper {
        .leaderboard-container {
          .modal_body {
            background-color: #ffffff;
            .reward-sty {
              color: #ffffff;
            }

            .faqCircleStyle {
              .faq-icon {
                fill: #ffffff !important;
              }
            }
          }
        }
      }
      .prizes-slider-container,
      .prizes-slider-item,
      .prize-list-container,
      .list-group-item {
        background-color: #e3e9ff;
      }
      .div-group {
        color: #090e35;
      }
    }

    .desktop-back {
      .second-part {
        .border-style {
          .button-cashout {
            background-color: #2c45ff !important;
            color: #ffffff !important;
          }
        }
      }
    }

    .board-container {
      .div-group-body {
        .label-style {
          background-color: #c1c3b0 !important;
        }
      }
    }

    .play-btn-browse-container {
      .rank-btn {
        .rank-icon {
          fill: #ecff40 !important;
        }
      }

      .btn-sty {
        .play-icon {
          fill: #ecff40 !important;
        }
      }
    }

    .show-all-text {
      color: black !important;
    }

    .subtitle {
      color: #848586;
    }

    .tournament-history-btn {
      color: #000000;
    }

    .fontHeadStyle {
      color: #848586;
    }

    .desktop-footer {
      .active-link {
        .link-img {
          svg {
            fill: #2c45ff !important;
          }
        }
        .link-label {
          color: #2c45ff !important;
        }
      }
    }

    .half-style {
      background: #2c45ff !important;
    }

    .tile {
      background: #2c45ff !important;
    }

    .tile {
      fill: #ecff40 !important;
    }

    .btn-container {
      background: #ecff40 !important;
    }

    .rank-icon {
      fill: #00edf3 !important;
    }

    .btn-circle-with-icon {
      .rank-icon {
        fill: #2c45ff !important;
      }
    }

    .replay-icon {
      fill: #2c45ff !important;
    }

    .share-icon {
      fill: #2c45ff !important;
    }

    .browse-icon {
      fill: #2c45ff !important;
    }

    .icon-play {
      fill: #fff !important;
    }

    .play-icon {
      fill: #00edf3 !important;
    }

    .custom-popup {
      background-color: #f8f8f8 !important;
    }

    .title-text {
      color: #4c00f5 !important;
    }

    .whatsapp {
      background-color: #4c00f5 !important;
    }

    .ticket-status-bar {
      .label-sty,
      .icon-style {
        color: #feffff !important;
        svg {
          fill: #feffff !important;
        }
      }
      .upper {
        background-color: #2c45ff !important;
      }
    }

    .tournament-list-popup,
    .leaderboard-popup-item {
      background-color: #f8f8f8 !important;
      border-top-left-radius: 16px !important;
      border-top-right-radius: 16px !important;
    }

    .close {
      fill: #4c00f5;
    }

    .login-class {
      fill: #4c00f5 !important;
    }

    .custom-page-modal-open {
      filter: opacity(0.5) brightness(20%) blur(1px) drop-shadow(2px 4px 6px #4c00f5);
    }

    .title {
      color: red;
    }
    .authentication {
      background-color: #ff7f00;
    }

    .btn-link {
      color: #4c00f5 !important;
    }

    .sub-button {
      background-color: #ff7f00 !important;
    }

    .back-arrow {
      fill: #00edf3 !important;
      stroke: #00edf3 !important;
    }

    .table-text {
      .end-time,
      .rank,
      .score,
      .prize-amount {
        color: #000000 !important;
      }
    }

    .latest-score-label {
      color: #fbff14 !important;
    }

    .board-container {
      .div-group-body {
        .label-style {
          background: #46cbce;
        }
      }
    }

    .ticket-status-bar {
      .label-sty,
      .icon-style {
        color: white !important;
        svg {
          fill: white !important;
        }
      }
    }

    .tournament-name {
      .txt-style {
        color: #000000;
      }
    }

    .won-label {
      .won-style {
        color: #000000;
      }
    }

    .scroll-bar {
      .rank {
        color: #ffffff;
      }
    }

    .second-row {
      .label {
        color: #000000 !important;
      }
    }

    .won-label {
      .dollar {
        color: #000000 !important;
      }
    }

    .leaderboard-container {
      .browse-popup {
        background-color: #ffffff !important;
        .small-card {
          background-color: #e3e9ff !important;
        }
      }
    }

    .modal_container_2 {
      .contain-style {
        .title-md {
          color: #ffffff !important;
        }
      }
    }
    .first-part {
      background: linear-gradient(200deg, #2c45ff 0%, #2c45ff 122.67%);
    }
    .heroes,
    .competitor-score,
    .winner-label,
    .own-name,
    .own-score {
      color: #ffffff;
    }
    .rank-list {
      .text-style {
        color: #ffffff;
      }
    }
    .mission-page-container {
      .section-header {
        color: rgb(9, 14, 53) !important;
      }
      .rounded-div {
        background: #ffffff;
      }
    }
    .recommended-games,
    .recommended-tournament-container {
      background-color: #ffffff;
    }
    .recommended-games {
      .header-style {
        color: #090e35;
        padding-left: 0.5rem !important;
      }
    }
    .ticket-redeem-page {
      .subtitle-note {
        color: #090e35;
      }
    }
    .swiper-wrapper {
      .badge-label-style {
        fill: #ecff40;
      }
    }
    .top-tournament-full-width {
      .swiper-wrapper {
        padding: 0 0 !important;
      }
    }
    .top-bar-container {
      button {
        background-color: #e3e9ff;
        cursor: pointer;
        &:focus {
          outline: none;
        }
      }
    }

    .partner-status {
      background-color: rgb(44, 69, 255) !important;
      box-shadow: none !important;

      .label-sty {
        color: #feffff !important;
      }

      .icon-style {
        color: #feffff !important;
      }
    }

    .footer-content {
      background-color: transparent;
    }

    &.${LAYOUT_TYPES.DARK_V2} {
      .page-container {
        background-color: #ffffff;
      }
      .home-container {
        .header {
          color: #090e35;
        }
      }
      .fontSubStyle {
        color: #090e35;
      }
      .ranks-wrapper {
        .list-group-item {
          background-color: #ffffff;
        }
      }
      .rank-list-container-first {
        .list-group-item:last-child {
          background-color: #e3e9ff;
        }
      }
      .navbar-container {
        .back-arrow {
          fill: #2c45ff !important;
          stroke: #2c45ff !important;
        }
      }

      .image-down-shadow {
        box-shadow: inset 0 -89px 40px -2px #fffffffc !important;

        .game-details {
          background: #ffffff !important;
          color: #000000 !important;
          font-weight: 700 !important;

          span {
            @media (width < 23.438rem) {
              font-size: 0.813rem !important;
            }

            @media (width < 20.625rem) {
              font-size: 0.75rem !important;
            }
          }
        }
      }

      .half-style {
        background: #ffffff !important;
        .latest-score-container {
          .latest-score-label {
            color: #000000 !important;
          }
          .latest-score {
            color: #000000 !important;
          }
        }
        .player-rank-board {
          .board-container {
            .first-board,
            .middle-board,
            .last-board {
              .tile {
                fill: #2c45ff !important;
                background: #ffffff !important;
              }

              .div-group-body {
                .score-body {
                  .name-style {
                    color: #ffffff !important;
                    font-weight: 700;
                  }

                  .label-style {
                    color: #ffffff !important;
                    background: #ecff40 !important;
                    .score {
                      color: #2c45ff !important;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .second-half {
        .btn-circle-with-icon {
          background: #2c45ff !important;
          box-shadow: none !important;

          svg {
            fill: #ffffff !important;
          }
        }

        .btn-replay {
          background: #2c45ff !important;

          svg {
            fill: #ffffff !important;
          }
        }
      }

      .timer-row {
        color: #000000 !important;
        font-weight: 700 !important;
        font-size: 15px !important;

        svg {
          fill: #000000 !important;
          height: 15px !important;
          width: 15px !important;
        }

        .icon-clock2 {
          fill: #000000 !important;
        }
      }

      .participant-counter-row {
        .participant-numb {
          color: #000000 !important;
          font-weight: 700 !important;
          font-size: 15px !important;
        }

        svg {
          fill: #000000 !important;
        }
      }

      .swiper-pagination-bullet {
        background: #82828d !important;
      }

      .swiper-pagination-bullet-active {
        background: #989898 !important;
      }

      .button-alignment {
        align-items: flex-start !important;

        .rank-btn {
          background: #2c45ff;
          color: #feffff;
        }

        .text-play {
          color: #feffff;
          background: #090e35;
        }
      }

      .browse-games {
        .header-container {
          .title {
            color: #090e35 !important;
          }
        }
      }

      .button-group {
        .login-active {
          background: #2c45ff !important;
        }
      }

      .desktop-modal-content-body {
        .title {
          color: #ff7f00 !important;
        }
      }

      .btn-sign-up {
        background: #2c45ff !important;
        color: #ffffff !important;
      }

      .btn-login {
        background: #ecff40 !important;
        color: #2c45ff !important;
      }

      .goama-font {
        bottom: 13% !important;
        .game-name {
          font-size: 14px !important;
        }
      }

      .lower-div-item {
        .icon {
          svg {
            fill: #2c45ff !important;
          }
        }
      }

      .desktop-play-button-backward {
        .desktop-play-footer {
          background: #090e35 !important;
          color: #feffff !important;
          box-shadow: none !important;

          .title-xs {
            @media (width < 23.438rem) {
              font-size: 0.875rem !important;
            }

            @media (width < 20.313rem) {
              font-size: 0.75rem !important;
            }
          }
        }
      }

      .play-btn-browse-container {
        .rank-btn {
          .rank-icon {
            fill: #ffffff !important;
          }
        }
        .play-btn-browse {
          background: #2c45ff;
          color: #ffffff;

          .play-icon {
            fill: #ffffff !important;
          }
        }
      }
      .browse-popup {
        .rank-btn {
          background: #090e35;
        }
        .gamename-style,
        .title-md {
          color: #090e35 !important;
        }
        .name-sty {
          font-weight: 600;
        }
        .text-sty-label {
          font-weight: 400;
        }
      }
      .avatar-update-modal {
        .update-button {
          background: #2c45ff !important;
        }
      }

      .selected-avatar {
        color: #2c45ff !important;
      }

      .rank-list-card {
        .list-heading {
          border-radius: 5px !important;
          margin-bottom: 5px !important;
        }
      }

      .rank-list-card {
        .list-heading > * {
          color: #090e35 !important;
        }
      }

      .profile-history-wrapper {
        .back-ground {
          background: #2c45ff !important;
          color: #ffffff !important;
        }
      }

      .button-bar {
        .btn-container {
          box-shadow: none !important;
        }
      }

      .second-half {
        .btn-replay {
          box-shadow: 0 12px 17px -7px #2c45ff !important;
        }
      }
      .second-part {
        .activated-button {
          color: #090e35;
        }
        .not-active-button {
          opacity: 0.75;
        }
        .rank-list-container {
          h5 {
            color: #090e35;
          }
        }
        .show-more {
          color: #090e35;
        }
      }
      .latest-score-container {
        .latest-score-label {
          color: #ffffff !important;
          font-weight: 600 !important;
        }
      }

      .games-of-the-week,
      .browse-game-list,
      .top-featured {
        margin: 0 !important;

        .swiper {
          /* margin: 0 0 0 0.5rem !important; */
        }

        .swiper-pagination {
          padding-right: 0.5rem;
          position: relative !important;
          height: 2.375rem !important;
          bottom: 0 !important;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .swiper-wrapper {
          display: flex;
          gap: 1rem;

          .swiper-slide {
            width: 10rem !important;
            height: fit-content !important;
            margin-right: 0 !important;

            .top-tournament-card {
              height: fit-content !important;
              overflow: visible !important;
              margin-bottom: 0 !important;
              padding: 0 !important;

              .card {
                border-radius: 0.625rem 0.625rem 0 0 !important;
                height: 11.399rem !important;
                margin-top: 0 !important;

                .img-style {
                  position: initial !important;
                  z-index: 0 !important;
                  top: 0 !important;
                  margin-bottom: 0 !important;
                  width: 100% !important;
                  height: 100% !important;
                }

                .img-reflect {
                  display: none !important;
                }
              }

              .bottom-bar-container {
                position: relative !important;
                bottom: 0 !important;
                height: 4.75rem !important;

                .game-name {
                  font-size: 1rem !important;
                }
              }
            }

            .browse-games-rectangular-card {
              background-color: #fcfcfc !important;
              border-radius: 0.625rem !important;
              border: none !important;
              box-shadow: 0 0 1px 0 rgba(9, 30, 66, 0.31), 0 3px 5px 0 rgba(9, 30, 66, 0.2) !important;
              height: 12.25rem !important;

              .browse-games-rectangular-card-top {
                margin: 0 !important;
                border-radius: 0.625rem 0.625rem 0 0 !important;

                img {
                  border: none !important;
                  border-radius: 0.625rem 0.625rem 0 0 !important;
                  width: 100% !important;
                  height: 7.5rem !important;
                }
              }

              .top-design {
                .game-name {
                  color: #ffffff !important;
                }
              }

              .browse-games-rectangular-card-bottom {
                height: 4.75rem;
                padding: 1rem;

                .game-name {
                  color: #090e35 !important;

                  span {
                    margin-right: 0 !important;
                  }
                }
              }
            }
          }
        }
      }

      .top-featured {
        margin-top: 1rem !important;

        .header-container {
          margin-bottom: 1rem !important;

          .title {
            .title {
              padding-left: 0 !important;
            }
          }
        }
      }

      .group-btn {
        .token-status {
          background: #2c45ff !important;
        }

        .ticket-status-bar {
          .upper {
            background: #2c45ff !important;
            box-shadow: none !important;
          }
        }
      }

      .token-sty {
        .token-status {
          background: #2c45ff !important;
        }

        .ticket-status-bar {
          .upper {
            background: #2c45ff !important;
            box-shadow: none !important;
          }
        }
      }

      .button-group {
        .text-header {
          color: #000000 !important;
        }

        .token-status {
          background: #2c45ff !important;
        }

        .ticket-status-bar {
          .upper {
            background: #2c45ff !important;
            box-shadow: none !important;
          }
        }
      }

      .custom-page {
        .first-part {
          background: #2c45ff !important;
        }
      }

      .pepsico-rank-list {
        .rank-list-container-first {
          .list-heading {
            background: #2c45ff !important;
          }
        }
      }

      .top-design {
        .tournament-order {
          color: #090e35 !important;
          font-weight: 700 !important;
        }
        .game-name {
          font-size: 11px !important;
          font-weight: 800 !important;
        }
        path {
          stroke: #090e35;
        }
      }
      .page-wrapper {
        .top-bar {
          .top-bar-top {
            background: #ffffff !important;
            box-shadow: 1px 5px 20px 0 rgb(137 123 123 / 50%) !important;
          }
          .top-bar-bottom {
            color: #000;

            .ticket-info-container {
              padding-right: 1rem !important;

              .ticket-info-title {
                @media (width < 26.563rem) {
                  font-size: 0.875rem !important;
                }

                @media (width < 24.375rem) {
                  font-size: 0.75rem !important;
                }
              }
            }
          }
          .redeem {
            background: #2c45ff !important;
            color: #ffffff !important;
          }
        }

        .background-contain {
          .header-style {
            background: #2c45ff !important;
            color: #ffffff !important;
          }
        }

        .first-section {
          background: #ffffff !important;
          box-shadow: 1px 5px 20px 0 rgb(137 123 123 / 50%) !important;

          .text-value {
            color: #000000 !important;
          }
        }

        .balance {
          .token-balance {
            color: #000000 !important;
          }

          .text-value {
            color: #000000 !important;
          }
        }

        .header-section {
          color: #000000 !important;
        }
      }

      .board-container {
        .div-group-body {
          .label-style {
            background: #ecff40 !important;
          }
        }
      }

      .cards-lists-container {
        .single-game-card {
          .square-card {
            background: #ffffff !important;
            box-shadow: 1px 5px 20px 0 rgb(137 123 123 / 50%) !important;
            border: none !important;

            .card-title {
              color: #090e35 !important;
            }

            .circle_content {
              .helper_text {
                color: #090e35;
                font-weight: 700;
              }
            }
            .small_button {
              box-shadow: none;
            }
          }

          .square-card.active-card {
            background: #2c45ff !important;
            box-shadow: 0 5px 20px #2c45ff !important;

            .card-title {
              color: #ffffff !important;
            }
            .circle_content {
              .helper_text {
                color: #000 !important;
              }
            }
            .progress_bottom_curve {
              background: #9771f0 !important;
            }
          }
        }

        .progress_bottom_curve {
          background: #ffffff !important;

          .game_info {
            .time_counter_title {
              color: #2c45ff !important;
            }

            .timer {
              color: #737d99 !important;

              .clock_icon {
                svg {
                  fill: #737d99 !important;
                }
              }

              .timer-data {
                color: #737d99 !important;
              }
            }
          }
        }
      }

      .game-info-column {
        .game-details {
          .game-name {
            .text-marquee {
              width: 145px !important;
              max-width: 145px !important;
            }
          }
        }
      }

      .button-pepsico {
        .active-button {
          &::before {
            border: 1px solid #2c45ff;
          }
        }
      }

      .loading-style {
        background: #2c45ff !important;
      }

      .progressbar_wrapper {
        .RCP {
          .RCP__track {
            stroke: #e3fdff !important;
          }

          .RCP__progress {
            stroke: #2c45ff !important;
          }
        }

        .circle_content {
          .small_circle {
            border: 7px solid #e3fdff !important;
          }
        }
      }
      .header-container {
        .title {
          color: #090e35 !important;
        }
      }

      .swiper-button-next-unique {
        display: none !important;
      }

      .triangle-div {
        .triangle-button {
          background: #2c45ff !important;

          svg {
            fill: #ffffff !important;
          }
        }
      }

      .game-image {
        right: 16px !important;
      }

      .game-details {
        .game-name {
          font-size: 15px !important;
          padding-top: 5px !important;
        }
      }

      .square-card {
        .card-title {
          color: #ffffff !important;
          margin-bottom: 0.75rem;
        }
      }

      .desktop-back {
        .second-part {
          .border-style {
            .button-cashout {
              box-shadow: 1px 5px 20px 0 rgb(137 123 123 / 50%) !important;
            }

            .grey-scale {
              background-color: #dddae5 !important;
              color: #c1bfc7 !important;
            }
          }
        }
      }
      .desktop-view-container {
        background-color: #ffffff;
      }
      .browse-games-container {
        .game-name,
        .label-count {
          color: #ffffff;
        }
        .player-numb {
          svg {
            fill: #ffffff;
          }
        }
      }
      .leaderboard-popup-item {
        .gamename-style {
          color: #090e35;
        }
      }
      .profile-history-wrapper {
        background-color: #ffffff;
      }
      #icon-edit-profile {
        fill: #ffffff;
      }
      .close {
        fill: #090e35 !important;
      }
      .desktop-sidebar {
        .sidebar-container {
          .link-label {
            color: #090e35 !important;
          }
          a:not(.active-link) {
            .link-img {
              color: #090e35 !important;
              fill: #090e35 !important;
            }
          }
          .active-link {
            .link-label {
              color: #2c45ff !important;
            }
          }
        }
      }
      .headerInfoWrapper {
        .text-style {
          color: #ffffff;
        }
      }
    }
    .desktop-footer {
      background-color: #ffffff;
    }
    .ticket-history-body {
      background-color: #ffffff;
    }
    .btn-tap-to-see {
      button {
        background: #2c45ff !important;
        color: #ffffff !important;
      }
    }
    .cancel-btn {
      background: transparent;
      border: 2px solid #2c45ff;
      color: #090e35;
      &:focus {
        outline: 0;
      }
      &:active {
        outline: 0;
        background-color: transparent !important;
      }
    }

    .text-marquee {
      color: #090e35 !important;
    }

    .card {
      border-radius: 0.625rem !important;
    }

    .bottom-bar-container {
      background-color: #fcfcfc !important;
      border-radius: 0 0 0.625rem 0.625rem !important;
      border: none !important;
      box-shadow: 0 0 1px 0 rgba(9, 30, 66, 0.31), 0 3px 5px 0 rgba(9, 30, 66, 0.2) !important;
      height: 4.719rem;
      padding: 1rem;
      width: 100%;

      .tournament-subtitle,
      .text-marquee {
        color: #575f80 !important;
      }
    }

    .game-name {
      overflow: hidden;
      color: #090e35 !important;
      text-overflow: ellipsis;
      font-size: 0.7rem;
      font-weight: 600;
      line-height: 140%;
      text-align: left;
    }

    .triangle-bg,
    .triangle-button {
      display: none !important;
    }

    .browse-game-card {
      background-color: #fcfcfc !important;
      border-radius: 0.625rem !important;
      border: none !important;
      box-shadow: 0 0 1px 0 rgba(9, 30, 66, 0.31), 0 3px 5px 0 rgba(9, 30, 66, 0.2) !important;
      height: 12.25rem !important;
      margin: 0 1rem 1rem 0 !important;
      max-width: fit-content;
      padding-left: 0 !important;

      .game-info {
        width: 100% !important;
        margin-bottom: 0 !important;

        .image-style {
          border-radius: 0.625rem 0.625rem 0 0 !important;
          height: 7.5rem !important;
          min-height: auto !important;
          width: 9.375rem !important;
          object-fit: initial !important;
        }

        .info-container {
          height: 4.75rem !important;
          padding: 1rem !important;
          width: 9.375rem !important;

          .game-name {
            font-size: 0.875rem !important;
            line-height: 1.25rem !important;
          }

          .player-numb {
            display: none !important;
          }
        }
      }
    }

    .header-container {
      .title {
        padding-left: 0.5rem !important;
      }
    }

    .recommended-games-container {
      padding: 0 1rem !important;
      position: static !important;
      top: auto !important;

      .recommended-games {
        .games-container {
          justify-content: center !important;
          margin: 0 !important;
          padding: 1.5rem 0 0 0 !important;
          gap: 1rem;

          @media (width > 30rem) {
            gap: 1.5rem;
          }

          .browse-game-card {
            margin-right: 0 !important;
          }
        }
      }
    }

    .background-contain {
      .subtitle-4 {
        color: #332927 !important;
      }
    }

    .competitor-name {
      color: #ffffff !important;
    }

    .action_button {
      svg {
        color: #feffff;
      }
    }
    @media (max-width: 1021px) {
      .top-tournament-full-width {
        padding-top: 0;
      }
    }

    .coming-soon-text {
      color: #2c45ff !important;
    }

    .terms-condition-page {
      p {
        color: #000000 !important;
      }
    }
  }
`;

export default pepsicoStyles;

import React from "react";
import { useAppSelector } from "redux/Store";
import { useLocation } from "react-router-dom";
import { avatars, defaultAvatar, isValidProfilePicId } from "utils/avatars";
import { css } from "@emotion/react";
import cn from "classnames";
import { RootState } from "redux/Store";
import { isRazer } from "utils/applicationSlug";
import analytics from "utils/analytics";
import { lighten, rgba } from "polished";
import AvatarFrame from "./AvatarFrame";
import { useHistory } from "hooks/useHistory";

interface AvatarProps {
  onClick?: () => void;
  userExtraData: ExtraData;
}

const Avatar = ({ onClick = () => {}, userExtraData }: AvatarProps) => {
  const { pathname } = useLocation();
  const { application, profilePic } = useAppSelector((state: RootState) => state.common);
  const { isDesktop } = useAppSelector((state: RootState) => state.layout);
  const { profile_pic, profile_pic_id, avatar_frame_url } = userExtraData;
  const history = useHistory();

  let avatarUrl;

  if (profilePic) {
    avatarUrl = profilePic;
  } else if (profile_pic) {
    avatarUrl = profile_pic;
  } else if (isValidProfilePicId(profile_pic_id)) {
    avatarUrl = avatars[profile_pic_id as number];
  } else {
    avatarUrl = defaultAvatar;
  }

  const isProfileActive = pathname === "/profile";

  const className = cn({
    emptyDivCss: isProfileActive,
    avatarCss: !isProfileActive,
    "border-0": isRazer,
    [`app-${application?.slug}`]: !isDesktop && !isRazer,
  });

  const avatarCss = css`
    background-image: url(${avatarUrl});
  `;

  const onClickHandler = () => {
    if (isDesktop) onClick();
    else {
      history.push("/profile");
      analytics.clickedProfileLink();
    }
  };

  const properties = {
    onClick: onClickHandler,
    onKeyDown: onClickHandler,
    role: "button",
    tabIndex: 0,
  };

  const currentCss = isDesktop ? desktopCss : mobileCss;

  return (
    <div className={`app-${application?.slug}`} css={currentCss}>
      <div className={className} css={[avatarCss]} {...properties}>
        {avatar_frame_url && <AvatarFrame url={avatar_frame_url} />}
      </div>
    </div>
  );
};

const desktopCss = (theme: ITheme) => css`
  .emptyDivCss {
    width: 40px;
    height: 40px;
    background-color: transparent;
    background-size: contain;
    border-radius: 50%;
    border: 1px solid #2125291f;
    box-shadow: 0 9px 12px 0 ${rgba(theme.button.primaryGradientStart, 0.5)};
    margin-bottom: 10px;
    background-repeat: round;
  }
  .avatarCss {
    background-color: #fff;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    border: 1px solid #2125291f;
    box-shadow: 0 9px 12px 0 ${rgba(theme.button.primaryGradientStart, 0.5)};
    cursor: pointer;
    height: 40px;
    position: relative;
    width: 40px;
    z-index: 1;
  }
  .razer .avatarCss {
    border: 0;
  }
  .profile_avatar_frame {
    position: absolute;
    width: calc(100% * 1.25);
    height: calc(100% * 1.25);
    top: -5px;
    right: -5px;
  }
  &.app-globe {
    .avatarCss {
      box-shadow: 0 9px 12px 0 ${rgba(34, 116, 229, 0.5)};
    }
  }
`;

const mobileCss = (theme: ITheme) => css`
  .emptyDivCss {
    height: 35px;
    background-color: transparent;
  }
  &.globe {
    &.back-button-wrapper {
      width: unset;
    }
  }
  .avatarCss {
    position: relative;
    width: 35px;
    height: 35px;
    z-index: 1;
    cursor: pointer;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid ${theme.button.primaryGradientStart};
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    &.app-globe {
      border: 0;
    }
  }

  .razer .avatarCss {
    border: 0;
  }

  &.backButtonCss {
    width: 35px;
    height: 35px;
    z-index: 1;
    border: none;
    cursor: pointer;
    font-size: 14px;
    overflow: hidden;
    line-height: 37px;
    padding-right: 2px;
    text-align: center;
    border-radius: 50%;
    background-color: ${theme.foreground.primary};
    box-shadow: 0 1px 6px 3px ${lighten(0.7, theme.button.primaryShadow)};

    svg line {
      fill: ${theme.button.primaryGradientStart} !important;
      color: ${theme.button.primaryGradientStart} !important;
    }
  }

  &.closeButton {
    z-index: 1;
    background-color: ${theme.background.primary};
    color: ${theme.text.primary};
    border-radius: 50%;
    width: 40px !important;
    height: 40px;
    justify-content: center;
    align-items: center;
    margin-right: auto;
    svg {
      margin-right: 0 !important;
    }
  }

  .back-arrow {
    margin-right: 5px;
    cursor: pointer;
  }

  .back-font {
    color: ${lighten(0.1, theme.button.primaryGradientStart)} !important;
    font-size: 15px;
  }

  &.button-flex {
    flex-grow: 1;
  }

  .foodpanda-title {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0;
    text-align: left;
    margin-bottom: 0;
    margin-left: 20px;
  }

  .profile_avatar_frame {
    position: absolute;
    width: calc(100% * 1.25);
    height: calc(100% * 1.25);
    top: -5px;
    right: -5px;
  }
`;

export default Avatar;

import { css } from "@emotion/react";

const globeStyles = (theme: ITheme) => css`
  &.globe {
    body {
      color: #2c4763;
    }
    .container,
    .custom-page {
      background-color: #f2f4ff;
    }
    .play-button {
      background-color: #2274e5 !important;
    }
    .triangle-button {
      background-color: #2274e5 !important;
      box-shadow: 0 10px 15px -3px rgba(34, 116, 229, 1), 0 10px 15px -3px rgba(0, 0, 0, 0.1) !important;
    }
    .play-btn {
      background: #2274e5 !important;
      box-shadow: 0 10px 15px -3px rgba(34, 116, 229, 1), 0 10px 15px -3px rgba(0, 0, 0, 0.1) !important;
    }
    .play-icon {
      fill: #fff;
    }
    .title-md,
    .notification-style {
      color: #1a458b;
      text-transform: uppercase;
    }
    .on.btn-primary {
      border-color: #2274e5 !important;
    }
    .switch-on {
      background: #2274e5 !important;
    }
    .off.btn {
      border-color: #2274e5 !important;
    }
    .switch-off {
      background: #2274e5 !important;
    }
    .profile-page-body {
      .title-md {
        font-size: 18px;
      }
      .pvp-stats {
        .iconplayer {
          fill: ${theme.button.primaryGradientStop};
        }
      }
    }

    .update-button {
      background: #2274e5 !important;
    }

    .half-style {
      /* .half-style { */
      background: rgba(16, 67, 137, 1) !important;
      /* } */
      .label-style {
        background: rgba(254, 225, 212, 1);
      }
      .score {
        color: rgba(44, 51, 135, 1);
        font-size: 12px;
        line-height: 2;
      }
      .first-board {
        .tile {
          fill: rgb(175, 29, 69);
        }
      }
      .middle-board {
        .tile {
          fill: #0f89e9;
        }
      }
      .last-board {
        .tile {
          fill: #6095c3;
        }
      }
      .btn-container {
        background: #2274e5;
        box-shadow: none;
      }
      .navbar-container {
        padding-left: 15px;
      }
    }

    .player-rank-board {
      .tilemap1 {
        .first-tilemap {
          fill: url(#tile-first-gradient) !important;
        }
        .gradient-start {
          stop-color: #af1d45;
        }
        .gradient-stop {
          stop-color: #5a1234;
        }
      }

      .tilemap2 {
        .second-tilemap {
          fill: url(#tile-second-gradient) !important;
        }
        .gradient-start {
          stop-color: #65d3ff;
        }
        .gradient-stop {
          stop-color: #0f89e9;
        }
      }

      .tilemap3 {
        .third-tilemap {
          fill: url(#tile-third-gradient) !important;
        }
        .gradient-start {
          stop-color: #eff2f8;
        }
        .gradient-stop {
          stop-color: #6095c3;
        }
      }
    }

    .btn-style {
      background: #2274e5 !important;
      box-shadow: 0 10px 15px -3px rgba(34, 116, 229, 1), 0 10px 15px -3px rgba(0, 0, 0, 0.1) !important;
    }

    .trending-game-carousel h1 {
      color: #1a458b;
      text-transform: uppercase;
    }

    .arcade-headerSty2,
    .arcade-headerSty {
      color: #1a458b;
      text-transform: uppercase;
    }

    [data-rsbs-overlay],
    [data-rsbs-backdrop],
    [data-rsbs-root]:after {
      background: rgba(243, 246, 255, 1);
    }

    .modal-open {
      .navbar-top-logo-2 {
        display: none;
      }
      .navbar-container {
        padding-left: 20px !important;
      }
    }

    .subtitle-text {
      color: #104389 !important;
    }

    .labelbold-style {
      color: #2c4763;
    }

    .iconLabel-style,
    .profile-icon,
    .timer-icon,
    .rank-btn,
    .text-sty-label,
    .user,
    .competitor-name,
    .own-score,
    .clock-icon {
      color: #1a458b;
      fill: #1a458b !important;
    }

    .rank-btn {
      border-color: #1a458b !important;
      padding: 7px 10px !important;
    }

    .list-heading {
      background-color: #2274e5;
    }

    .tournament-list-popup {
      .sub-title {
        color: #1a458b;
      }
    }

    .no-back-btn {
      .emptyDivCss {
        width: unset !important;
      }
    }
  }
`;

export default globeStyles;

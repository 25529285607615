import { css } from "@emotion/react";
import { LAYOUT_TYPES } from "constants/constants";

const gcashAliPlusStyles = (theme: ITheme) => css`
  &.gcashaliplus {
    &.DARK {
      body {
        background-color: #000000 !important;
      }
    }
    .half-style {
      width: 100vw;
      background: transparent linear-gradient(311deg, #3560e3 0%, #3d6de6 34%, #0da0fd 100%) 0% 0% no-repeat padding-box !important;
      .latest-score {
        font-size: 2em;
      }
    }
    .progress-share-modal_survery-card-list-container {
      margin-top: 2px !important;
    }
    .campaign-message-modal {
      .modal-body {
        border-radius: 15px;
      }
      .title {
        color: #1479f7;
      }
      .close-button {
        color: #ffffff;
      }
    }
    .campaign-message-modal-footer {
      border-radius: 15px;
      .campaign-message-modal-footer-button {
        /* background: #1479f7 0% 0% no-repeat padding-box; */
        box-shadow: 0 15px 10px #1479f740;
      }
    }
    .browse-games-container {
      background: #f6f6f6 0% 0% no-repeat padding-box;
    }
    .card-win-prizes {
      &.mobile-view {
        background: transparent linear-gradient(90deg, #ee5b65 0%, #d41461 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0 15px 15px #f579254d;
      }
    }
    .second-half {
      .btn-replay {
        background: #1479f7 0% 0% no-repeat padding-box;
        border: 1px solid #ffffff;
      }
      .button-bar {
        .btn-container {
          box-shadow: 0 12px 17px -7px #f00064;
          border: none;
          &.btn-home,
          &.btn-leaderboard,
          &.btn-share {
            background: #ffffff 0% 0% no-repeat padding-box;
            svg {
              fill: #f00064;
            }
          }
        }
      }
    }
    &.${LAYOUT_TYPES.DARK_V2} {
      .ticket-status {
        .label-sty {
          color: ${theme.button.buttonTextColor} !important;
        }
      }
      .navbar-home {
        .header-style {
          background: #3e3a39 !important;
        }
      }

      .homepage-background {
        box-shadow: -1pt 6pt 5px 0 #c7f1fe !important;
      }

      .desktop-footer {
        .footer-content {
          border-top: 2px solid #1677ff;
          background-color: #000000ed;
        }
        .link-img {
          fill: #ffffff !important;
        }
        .link-label {
          color: #ffffff !important;
        }
        .active-link {
          .link-img {
            fill: #1677ff !important;
          }
          .link-label {
            color: #1677ff !important;
          }
        }
      }

      .browse-games-container {
        background: #3e3a39 !important;

        .header-container {
          .title {
            color: white;
          }
        }
      }

      .active-link .link-label {
        color: #1677ff !important;
      }

      .active-link .link-img {
        fill: #1677ff !important;
      }

      .rank-timer {
        .play-btn {
          background-color: #feee1c !important;
        }
      }

      .btn-leaderboard {
        .rank-icon {
          fill: #1677ff !important;
        }
      }

      .leaderboard-popup-item {
        .modal_header {
          background: #3e3a39;
        }

        .modal_body {
          background: #3e3a39;
        }

        .desktop-play-footer,
        .btn-style {
          background: #1677ff !important;
        }
      }

      .tournament-games-page {
        .title-md {
          color: #ffff;
        }
      }

      .lower-div-items {
        .icon {
          svg {
            fill: #1677ff !important;
          }
        }
      }

      .browse-popup {
        background: #3e3a39 !important;
      }

      .contain-style {
        .title-md {
          color: #ffff;
        }
      }

      .user-profile-wrapper,
      .profile-page-body {
        .title-md {
          color: #ffff;
        }
      }

      .title-md {
        /* color: black; */
      }

      .gamename-style {
        color: white;
      }

      .close_wrapper {
        .close {
          fill: white !important;
        }

        .mob-close {
          fill: white !important;
        }
      }

      .drag-handle {
        fill: white !important;
      }

      .small-card {
        background: #ffffff !important; //blue
      }

      .name-sty {
        color: #000000;
      }

      .user,
      .clock-icon {
        fill: #000000;
      }

      .browse-popup {
        .gamename-style {
          color: white;
        }
      }

      .play-btn-browse {
        background: #fff700 !important;

        .play-icon {
          fill: #3e3a39 !important;
        }
      }

      .rank-btn {
        background: #3e3a39 !important;
      }

      .top-bar-top {
        background: #000000 !important;
      }

      .container-class,
      .profile-page,
      .tournament-history-page,
      .half-style {
        background: #000000 !important;
      }

      .card-prize {
        .card-body {
          color: #000000 !important;
        }
      }

      .label-no-ranks {
        color: #ffffff !important;
      }

      .avatarStyle {
        color: #000000 !important;
      }

      .fontSubStyle {
        color: white;
      }

      .show-all-text {
        color: white;
      }

      .back-ground {
        background: #1677ff !important; //blue
      }

      .back-arrow {
        fill: #ffff !important;
        stroke: #ffff !important;
      }

      .tournament-info-popup {
        background: #3e3a39 !important;
      }

      .header-line {
        color: #ffffff;
      }

      .competitor-name,
      .competitor-score {
        color: #ffffff;
      }
      .coupon-content {
        color: #000000;
      }
      .text-style-transaction {
        color: #000000;
      }
      .coupon-content {
        color: #000000;
      }
      .score-body {
        .label-style {
          background: #706d6c9e !important;
          color: #ffffff !important;

          .title-md {
            color: #ffffff !important;
          }
        }

        .name-style {
          color: #ffffff !important;
        }
      }

      .rank-list-container-first {
        .list-heading {
          background-color: #1677ff !important;
        }
      }

      .col-browse {
        .labelbold-style {
          color: #000000 !important;
        }
      }

      .row-style {
        .rank-timer {
          .play-btn {
            background: #1677ff !important;
          }
        }
      }

      .font-style {
        color: #1677ff !important;
      }

      .update-button {
        background-color: #1677ff !important;
      }

      .board-container {
        .first-board,
        .middle-board,
        .last-board {
          box-shadow: none !important;
        }
      }

      .second-half {
        .btn-container {
          box-shadow: none !important;
        }
      }

      .first-board,
      .last-board,
      .middle-board {
        background: #1677ff !important;
      }

      .history-container {
        .header-style {
          color: #ffffff !important;
        }
      }

      .rank-icon {
        fill: #ffffff;
      }

      .share-icon,
      .browse-icon {
        fill: #1677ff !important;
      }

      .history-border {
        .list-group-item {
          color: #000000 !important;
        }
      }

      .drag-handle:after {
        background-color: #ffffff !important;
      }

      .triangle-button {
        background: #fff700 !important; //blue
        box-shadow: 0 10px 10px rgb(218 255 18 / 38%) !important;
        .icon-play {
          fill: #000000;
        }
      }

      .recommended-games-container {
        .recommended-games {
          background-color: black;
        }
      }

      .reward-sty {
        color: #ffffff;
      }

      .mission-page-container {
        .single-game-card {
          .card-title {
            color: #000000;
          }
          .clock_icon {
            .clockIcon {
              fill: #ffffff;
            }
          }
          .progress_bottom_curve {
            span {
              /* color: #ffffff; */
            }
          }
        }
      }
    }
    .swiper-wrapper {
      .game-details {
        z-index: 1;
        text-shadow: 1px 1px 1px #000;
        span {
          &.game-subtitle {
            .text-marquee {
              font-size: 0.9rem;
            }
          }
          &.game-name {
            .text-marquee {
              font-size: 1.1rem;
            }
          }
        }
        .participant-counter-row {
          span {
            font-size: 0.8rem;
          }
        }
        .game-name {
          text-align: left;
        }
      }
      .button-alignment {
        /* bottom: 40px; */
      }
      .game-details {
        /* bottom: 0; */
      }
    }

    .div-image {
      visibility: hidden !important;
    }

    .card-border {
      background-color: #000000 !important;
      border: 2px solid white;
    }

    .add-token-container {
      background-color: #000000 !important;
    }

    .draggable-modal {
      [data-rsbs-overlay] {
        background: #0332b2 !important;
      }
    }

    .single-token {
      svg {
        g {
          path {
            fill: #ffffff !important;
          }

          g {
            fill: #0079ff !important;
          }
        }
      }

      .value {
        color: #0079ff !important;
        font-weight: 600 !important;
        padding-top: 20px !important;
      }

      .price {
        color: #ffffff !important;
      }

      .label {
        color: #ffffff !important;
        font-size: 15px !important;
      }
    }

    .button-cashout[name="submit"] {
      margin-right: 10px;
    }

    .backside-color {
      .rank-btn {
        background-color: #ffffff !important;
      }
    }

    .page-wrapper {
      .first-section {
        background: #000000 !important;
      }
    }
    .top-bar-top {
      .redeem {
        color: #000000;
      }
    }
    .ticket-history-body {
      .subtitle {
        color: #000000;
      }
    }
    .game-over-mission-wrapper {
      .missions__item {
        background: #ffffff;
        &.claimable {
          background: #c7f1fe !important;
          .text-title {
            color: #222 !important;
          }
        }
        .text-title {
          color: #04bcf5;
        }
        .text-timer,
        .text-progress {
          color: #04bcf5 !important;
        }
        svg {
          fill: #04bcf5 !important;
        }
      }
    }
  }
`;

export default gcashAliPlusStyles;

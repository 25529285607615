import React, { useState } from "react";
import { css } from "@emotion/react";
import { lighten } from "polished";
import { useAppSelector } from "redux/Store";
import { ReactComponent as Editor } from "assets/images/icon-edit-profile.svg";
import { ReactComponent as Notification } from "assets/images/icon-notification.svg";
import { ReactComponent as NiuEdit } from "assets/clients/Niu/Edit.svg";
import { ReactComponent as ClaroEdit } from "assets/clients/Claro/Edit.svg";
import UpdateInfoModal from "components/feature-specific/Modals/UpdateInfoModal";
import { Link } from "react-router-dom";
import { isClaro, isNiu } from "utils/applicationSlug";
import { apiUserServiceBase } from "actions/api";

const background = (theme: ITheme) => css`
  .veri-style {
    background: ${theme.background.primary};
    border-radius: 6px;
    box-shadow: 1px 1px 11px 3px ${lighten(0.7, theme.button.primaryShadow)};

    width: 30px;
    height: 30px;
  }

  .profile-verification {
    width: 18px;
    height: 18px;
  }

  .support-style {
    background: ${theme.foreground.primary};
    border-radius: 8px;
    box-shadow: 0 20px 40px ${theme.button.primaryShadow};
    height: 30px;
  }

  .support-icon {
    width: 18px;
    height: 18px;
  }

  .notification-icon {
    width: 18px;
    height: 18px;
  }

  .profile-icon {
    width: 18px;
    height: 18px;
  }

  .border-style {
    border-right: 1px solid #cacaca;
  }

  .notify-number {
    position: absolute;
    top: 18px;
    font-size: 13px;
    color: red;
    font-weight: 600;
  }

  .notify-number-large {
    position: absolute;
    top: 18px;
    right: 69px;
    font-size: 13px;
    color: red;
    font-weight: 600;
  }
`;

export default function UserProfileCustomization({ notifyData }: any) {
  const [modalShow, setModalShow] = useState(false);
  const { application, user, profilePic } = useAppSelector((state: any) => state.common);
  const { isDesktop } = useAppSelector((state: any) => state.layout);
  const isProfilePic = profilePic || user?.extra_data?.profile_pic;

  const avatarShow = () => {
    if (!application?.is_custom_avatar_enabled && !isProfilePic) setModalShow(true);
  };

  let unseenNotifyData;

  if (notifyData?.length > 0) {
    unseenNotifyData = notifyData?.filter((d: any) => d?.status === "unseen");
  }

  const notificationsID = unseenNotifyData?.map((item: any) => {
    return { notify_id: item?.notification_id };
  });

  const notificationApiSeen = () => {
    apiUserServiceBase.post("api/user-notification-seen/", {
      user_id: user?.id,
      notifications: notificationsID,
    });
  };

  const showNotifications = !application?.disable_notification;
  const showUserConfigurationButton = user && application?.applicationsetting?.enable_profile_edit && !isProfilePic;

  if (!(showNotifications || showUserConfigurationButton)) return null;

  const currentEditIcon = () => {
    let Icon = Editor;

    if (isNiu) Icon = NiuEdit;
    if (isClaro) Icon = ClaroEdit;

    return <Icon className="edit-icon" />;
  };

  return (
    <>
      <div className="d-flex flex-row user-profile-customization" css={background}>
        {/* <div className="veri-style d-flex justify-content-center align-items-center me-2">
        <Verification />
      </div> */}
        {
          // !user?.extra_data.profile_pic &&

          <div className="d-flex flex-row justify-content-center align-items-center support-style pe-1 ps-1">
            {showNotifications && !isDesktop && (
              <div>
                {unseenNotifyData?.length < 10 ? (
                  <span className="notify-number">{unseenNotifyData?.length}</span>
                ) : (
                  <span className="notify-number-large">{unseenNotifyData?.length}</span>
                )}

                <Link to="/notifications" className="pe-2 ps-1 border-style" onClick={() => notificationApiSeen()}>
                  <Notification className="notification-icon" />
                </Link>
              </div>
            )}

            <div className="ps-2 pe-1">
              {showUserConfigurationButton && (isNiu || isClaro) && (
                <button className="edit-button" onClick={avatarShow}>
                  {currentEditIcon()}
                </button>
              )}
              {showUserConfigurationButton && !isNiu && !isClaro && (
                <Editor className="editor-icon" onClick={avatarShow} />
              )}
            </div>
          </div>
        }
      </div>
      <UpdateInfoModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}

import React, { lazy, useContext, useEffect } from "react";
import { css, useTheme } from "@emotion/react";
import Modal from "react-bootstrap/Modal";
import analytics from "utils/analytics";
import { NavLink } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/Store";
import cn from "classnames";
import { Trans } from "react-i18next";
import { fetchUserAuthLogin } from "actions";
import { ReactComponent as LoginPopUpBg } from "assets/images/Login Popup.svg";
import { ReactComponent as Facebook } from "assets/images/icon-facebook.svg";
import { ReactComponent as Google } from "assets/images/icon-google.svg";
import { ReactComponent as Close } from "assets/images/closedown.svg";
import Coins from "assets/images/Login Popup_1.png";
import { WindowSizeContext } from "providers/WindowSizeProvider";
import { decodeDataFromURL } from "utils";
import { fetchTranslations } from "redux/reducers/uiReducer";
import { isDemo, isElTiempo, isTrendi } from "utils/applicationSlug";
const AppleSignIn = lazy(() => import("components/common/AppleSignIn"));

const desktopCss = (theme: ITheme) => css`
  z-index: 100004 !important;
  &.desktop-modal {
    .loginpopup-desktop-background {
      fill: #ffffff;
    }
    height: 100%;
    overflow-y: hidden; /* Hide vertical scrollbar */
    overflow-x: hidden; /* Hide horizontal scrollbar */
    .desktop-modal-content {
      position: relative;
      width: 100%;
      height: 100vh;
      .bg-img {
        width: 100%;
        bottom: 0;
        padding: 0;
        margin: 0;
      }
      .desktop-modal-content-body {
        width: 100%;
        position: absolute;
        align-items: center;
        top: 47%;
        padding: 0 30px;
        margin: 0;

        @media (min-width: 1022px) and (max-width: 1250px) {
          top: 45%;
        }
      }
    }
  }

  &.desktop-modal::-webkit-scrollbar {
    display: none;
  }

  .close-button {
    position: absolute;
    width: 18px;
    height: auto;
    bottom: 60%;
    right: 11%;
  }

  .close {
    transform: translateY(-50%);
    margin-right: -1015px;
    bottom: 387px;
    width: 22px;
    height: 22px;
    opacity: 1.5 !important;

    @media (min-width: 1835px) and (max-width: 2000px) {
      margin-right: -1428px;
      bottom: 535px;
    }

    @media (min-width: 1700px) and (max-width: 1834px) {
      margin-right: -1329px;
      bottom: 511px;
    }

    @media (min-width: 1630px) and (max-width: 1699px) {
      margin-right: -1269px;
      bottom: 481px;
    }

    @media (min-width: 1500px) and (max-width: 1629px) {
      margin-right: -1224px;
      bottom: 465px;
    }

    @media (min-width: 1470px) and (max-width: 1499px) {
      margin-right: -1164px;
      bottom: 443px;
    }

    @media (min-width: 1430px) and (max-width: 1469px) {
      margin-right: -1120px;
      bottom: 422px;
    }

    @media (min-width: 1370px) and (max-width: 1429px) {
      margin-right: -954px;
      bottom: 354px;
    }

    @media (min-width: 1300px) and (max-width: 1369px) {
      margin-right: -972px;
      bottom: 366px;
    }

    @media (min-width: 1200px) and (max-width: 1299px) {
      margin-right: -973px;
      bottom: 353px;
    }

    @media (min-width: 1022px) and (max-width: 1199px) {
      margin-right: -911px;
      bottom: 330px;
    }
  }

  .modal-dialog {
    display: flex;
    position: relative;
    min-height: 100vh;
    width: 100% !important;
    min-width: 100vw !important;
    max-width: 100vw !important;
  }

  .modal-dialog::-webkit-scrollbar {
    display: none;
  }

  .modal-content {
    position: absolute;
    background-color: transparent;
    align-items: center;
    border: none;
  }

  .sign-up,
  .login {
    width: 50%;
    background: ${theme.background.primary};
    border-radius: 15px;
    border: 1px solid #ffffff;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.08);
    color: ${theme.text.primary};
    text-decoration: none;
    font-weight: 600;

    @media (min-width: 1000px) and (max-width: 1240px) {
      min-height: 65px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .title {
    font-size: 25px;
    font-weight: 800;
    color: black;
  }

  .face-button {
    background: #2079cf;
    color: white;
    font-weight: 500;
    align-items: center;
    border-radius: 15px;
    width: 40%;
    justify-content: space-between;
  }

  .google-button {
    background: #d32525;
    color: white;
    font-weight: 500;
    align-items: center;
    border-radius: 15px;
    width: 40%;
    justify-content: space-between;
  }

  .button {
    width: 40%;
  }

  .apple-button {
    background: black;
    color: white;
    font-weight: 500;
    align-items: center;
    border-radius: 15px;
    width: 40%;
    justify-content: space-between;
    padding-top: 6px;
    padding-bottom: 5px;

    .apple-div {
      /* margin-left: 5px; */
      width: 100%;
    }

    @media (max-width: 450px) {
      width: 90%;
      padding-top: 5px;
      padding-bottom: 4px;
    }

    @media (min-width: 450px) and (max-width: 1000px) {
      width: 65%;
      padding-top: 8px;
      padding-bottom: 7px;
    }
  }

  .react-apple-signin-auth-btn-dark {
    width: 100%;
    height: 37px;
    border: none;
    background-color: black !important;
    justify-content: space-between;
    /* padding-right: calc(40% + -25px); */
    padding-right: calc(100% * 0.34);

    @media (min-width: 1300px) and (max-width: 2000px) {
      padding-right: calc(100% * 0.37);
    }

    svg {
      width: 40px !important;
      height: 56px !important;

      #Rectangle {
        fill: black !important;
      }
    }

    &:focus {
      outline: 0 !important;
      border: none;
    }
  }
`;

const modalCSS = (theme: ITheme) => css`
  z-index: 100004 !important;
  &.desktop-modal {
    height: 100%;
    overflow-y: hidden; /* Hide vertical scrollbar */
    overflow-x: hidden; /* Hide horizontal scrollbar */
    .desktop-modal-content {
      width: 100%;

      .bg-img {
        width: 100%;
        bottom: 0;
        padding: 0;
        margin: 0;
      }

      .desktop-modal-content-body {
        align-items: center;
        justify-content: center;
        height: 50vh;
        padding: 0 30px;
      }
    }
  }

  .trendi-font-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    min-height: 45px;
  }

  &.desktop-modal::-webkit-scrollbar {
    display: none;
  }

  .modal-content {
    position: absolute;
    background-color: transparent;
    align-items: center;
    border: none;
  }

  .close-button {
    position: absolute;
    width: 18px;
    height: auto;
    top: 5%;
    right: 5%;
  }

  .title {
    font-size: 25px;
    font-weight: 800;
    color: black;
    padding-bottom: 30px;
    @media (max-width: 450px) and (min-width: 361px) {
      font-size: 20px;
    }

    @media (max-width: 360px) {
      font-size: 15px;
    }
  }

  .face-button {
    background: #2079cf;
    color: white;
    font-weight: 500;
    align-items: center;
    border-radius: 15px;
    width: 40%;
    justify-content: space-between;

    @media (max-width: 450px) {
      width: 90%;
    }

    @media (min-width: 450px) and (max-width: 1000px) {
      width: 65%;
    }
  }

  .google-button {
    background: #d32525;
    color: white;
    font-weight: 500;
    align-items: center;
    border-radius: 15px;
    width: 40%;
    justify-content: space-between;

    @media (max-width: 450px) {
      width: 90%;
    }

    @media (min-width: 450px) and (max-width: 1000px) {
      width: 65%;
    }
  }

  .apple-button {
    background: black;
    color: white;
    font-weight: 500;
    align-items: center;
    border-radius: 15px;
    width: 40%;
    justify-content: space-between;

    .apple-div {
      width: 100%;
      /* margin-left: 5px; */
    }

    @media (max-width: 450px) {
      width: 90%;
      padding-top: 5px;
      padding-bottom: 4px;
    }

    @media (min-width: 450px) and (max-width: 1000px) {
      width: 65%;
      padding-top: 8px;
      padding-bottom: 7px;
    }

    @media (min-width: 1001px) and (max-width: 1021px) {
      width: 40%;
      padding-top: 5px;
      padding-bottom: 4px;
    }
  }

  .button {
    width: 40%;

    @media (max-width: 450px) {
      width: 90%;
    }

    @media (min-width: 450px) and (max-width: 1000px) {
      width: 65%;
    }
  }

  .sign-up {
    width: 50%;
    background: ${theme.background.primary};
    border-radius: 15px;
    border: 1px solid #ffffff;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.08);
    color: ${theme.text.primary};
    text-decoration: none;
    font-weight: 600;
  }

  .login {
    width: 50%;
    background: ${theme.background.primary};
    border-radius: 15px;
    border: 1px solid #ffffff;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.08);
    color: ${theme.text.primary};
    text-decoration: none;
    font-weight: 600;
  }

  .background {
    background: ${theme.foreground.primary};
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
  }

  .modal-dialog {
    display: flex;
    position: relative;
    max-width: 120vh;
    top: 50%;
    margin: 0;
  }

  .modal-dialog::-webkit-scrollbar {
    display: none;
  }

  .modal-card {
    bottom: 0;
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-size: 50%;
    background-position: center;
  }

  .button-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 25px 0 0 0;

    .btn {
      border-radius: 12px;
      width: 170px;
      height: 45px;
      color: #feffff;
      font-weight: 600;
      font-size: 14px;
      line-height: 45px;
      margin-bottom: 10px;
      padding: 0;
    }
  }

  .text-muted {
    color: ${theme.text.secondary};
  }

  .coin-div {
    top: -158px;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    @media (min-width: 650px) {
      top: -265px;
    }
    @media (max-width: 360px) {
      top: -144px;
    }
  }

  .logo-coin {
    width: 330px;

    @media (min-width: 650px) {
      width: 550px;
    }

    @media (max-width: 360px) {
      width: 300px;
    }
  }

  .button-name {
    padding: 0.375rem 0.75rem;
    @media (max-width: 360px) {
      font-size: 15px;
    }
  }

  .brand-logo {
    display: flex;
    justify-content: center;
    position: absolute;
    top: -70px;
    left: 50%;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
    z-index: 55;
  }

  .react-apple-signin-auth-btn-dark {
    /* padding: 0 !important; */
    width: 100%;
    height: 37px;
    border: none;
    justify-content: space-between;

    background-color: black !important;

    @media (min-width: 1001px) and (max-width: 1021px) {
      padding-right: calc(100% * 0.26);
    }

    @media (min-width: 850px) and (max-width: 1000px) {
      padding-right: calc(100% * 0.34);
    }

    @media (min-width: 600px) and (max-width: 849px) {
      padding-right: calc(100% * 0.3);
    }

    @media (min-width: 300px) and (max-width: 599px) {
      padding-right: calc(100% * 0.2);
    }

    svg {
      width: 40px !important;
      height: 56px !important;

      #Rectangle {
        fill: black !important;
      }
    }

    &:focus {
      outline: 0 !important;
      border: none;
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function LoginPopUpModal({ show, title, body, onRetry, onHide, ...domProps }: any) {
  const dispatch = useAppDispatch();
  const theme: ITheme = useTheme();
  const { isDesktop } = useAppSelector((state: any) => state.layout);
  const breakpoint = 1022;
  const { width } = useContext(WindowSizeContext);
  const { application } = useAppSelector((state: any) => state.common);

  const enable_brand_logo_login_popup = application?.applicationsetting?.enable_brand_logo_login_popup;
  const brand_logo = application?.applicationsetting?.brand_logo;
  useEffect(() => {
    const { country } = decodeDataFromURL();
    application && application?.slug && dispatch(fetchTranslations(`${application?.slug}`, `${country}`));
  }, [application, dispatch]);

  const closeModal = () => {
    onHide();
  };

  return (
    <form>
      <Modal
        {...domProps}
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        css={breakpoint <= width ? desktopCss : modalCSS}
        className={cn("desktop-modal login-popup-modal p-0", {
          "mobile-modal": !isDesktop,
        })}
        backdrop="static"
      >
        <div
          className={cn("desktop-modal-content d-flex justify-content-center align-items-center", {
            eltiempo: isElTiempo,
          })}
        >
          <div className="w-100 mt-auto position-relative background">
            {width >= breakpoint && <LoginPopUpBg className="bg-img" />}

            {width < breakpoint && (
              <div>
                {brand_logo && (
                  <div className="brand-logo">
                    {enable_brand_logo_login_popup && (
                      <img
                        src={brand_logo}
                        alt="Thumbnail"
                        style={{
                          width: "45px",
                          height: "45px",
                        }}
                      />
                    )}
                  </div>
                )}
                <div className="coin-div">
                  <img src={Coins} alt="Coin Logo" className="logo-coin" />
                </div>
              </div>
            )}

            <div className="close-button" onClick={closeModal}>
              <Close fill={theme?.text?.secondary} />
            </div>
            <div className="desktop-modal-content-body d-flex flex-column">
              {!isTrendi && (
                <div
                  className={cn("title text-center ", {
                    /* "mb-1": width > 361 && isElTiempo,
                  "mb-5": width > 361 && !isElTiempo,
                  "mb-2": width <= 360 && !isElTiempo,
                  "mb-4": width <= 360 && isElTiempo */
                    "mb-5": width > 361,
                    "mb-2": width <= 360,
                  })}
                >
                  {!isDemo && !isTrendi && (
                    <Trans i18nKey="login-modal-tit">
                      {/* <span>
                    Play 150+ free games and{" "}
                    {(!isElTiempo || width < 1022) && <br />} Win cash prizes!
                  </span> */}
                      Play 150+ free games and <br /> Win cash prizes!
                    </Trans>
                  )}
                  {isDemo && <Trans i18nKey="login-modal-tit">Play 150+ free games</Trans>}
                </div>
              )}

              {application?.enable_social_auth && application?.facebook_auth && (
                <div
                  className={cn("d-flex flex-row face-button pointer", {
                    "mt-4 mb-3": true,
                  })}
                  onClick={() => {
                    analytics.loginByFacebook();
                    dispatch<any>(fetchUserAuthLogin(application?.slug, "facebook")).then((data: any) => {
                      if (localStorage) {
                        localStorage.setItem("auth-backend", "facebook");
                      }
                      if (application?.applicationsetting?.iframe_loading) window.parent.location.replace(data.login);
                      window.location.replace(data.login);
                    });
                    /* const tnc = getValues("terms");
                    if (!tnc && application?.slug === "el-tiempo") {
                      setError("terms", {
                        type: "required",
                        message:
                          "You must accept the terms and condition & privacy policy!"
                      });
                    } else {
                    } */
                  }}
                >
                  <div>
                    <Facebook />
                  </div>
                  <div className="button-name">
                    <Trans i18nKey="fb-continue-but">Continue with Facebook</Trans>
                  </div>
                  <div />
                </div>
              )}
              {application?.enable_social_auth && application?.google_auth && (
                <div
                  className={cn("d-flex flex-row pointer google-button", {
                    /* "mt-4 mb-3": !isElTiempo,
                    "mt-2 mb-2": isElTiempo,
                    "mt-5 mb-2": isElTiempo && width < 1022 */
                    "mt-4 mb-3": !application?.facebook_auth,
                  })}
                  onClick={() => {
                    analytics.loginByGoogle();
                    dispatch<any>(fetchUserAuthLogin(application?.slug, "google-oauth2")).then((data: any) => {
                      if (localStorage) {
                        localStorage.setItem("auth-backend", "google-oauth2");
                      }
                      if (application?.applicationsetting?.iframe_loading) window.parent.location.replace(data.login);
                      window.location.replace(data.login);
                    });
                    /* const tnc = getValues("terms");
                      if (!tnc && application?.slug === "el-tiempo") {
                        setError("terms", {
                          type: "required",
                          message:
                            "You must accept the terms and condition & privacy policy!"
                        });
                      } else {
                      } */
                  }}
                >
                  <div>
                    <Google />
                  </div>
                  <div className="button-name">
                    <Trans i18nKey="google-continue">Continue with Google</Trans>
                  </div>
                  <div />
                </div>
              )}

              {application?.enable_social_auth && application?.apple_auth && (
                <div
                  className={cn("d-flex flex-row pointer apple-button", {
                    "mt-4 mb-3": !application?.facebook_auth,
                    "mt-3 mb-2": application?.google_auth,
                  })}
                  // onClick={() => {
                  //   dispatch<any>(
                  //     fetchUserAuthLogin(application?.slug, "google-oauth2")
                  //   ).then((data: any) => {
                  //     if (localStorage) {
                  //       localStorage.setItem("auth-backend", "google-oauth2");
                  //     }
                  //     if (application?.applicationsetting?.iframe_loading)
                  //       window.parent.location.replace(data.login);
                  //     window.location.replace(data.login);
                  //   });
                  //   /* const tnc = getValues("terms");
                  //     if (!tnc && application?.slug === "el-tiempo") {
                  //       setError("terms", {
                  //         type: "required",
                  //         message:
                  //           "You must accept the terms and condition & privacy policy!"
                  //       });
                  //     } else {
                  //     } */
                  // }}
                >
                  <div className="d-flex apple-div position-relative text-center me-1 ms-1">
                    <AppleSignIn loginpop={true} />
                    {/* <Trans i18nKey="google-continue">Sign in with Apple</Trans>
                    <div className="button-name position-absolute "></div> */}
                  </div>

                  <div />
                </div>
              )}

              <div className="d-flex flex-row button mt-3">
                <NavLink
                  to="/signup"
                  onClick={closeModal}
                  className={cn("sign-up btn-sign-up text-center me-2 button-name", {
                    "trendi-font-mobile align-self-center": isTrendi,
                    "pt-2 pb-2": isDesktop,
                  })}
                >
                  <Trans i18nKey="sign-up">Sign up</Trans>
                </NavLink>
                <NavLink
                  to="/login"
                  onClick={closeModal}
                  className={cn("login btn-login text-center ms-2 button-name ", {
                    "trendi-font-mobile align-self-center": isTrendi,
                    "pt-2 pb-2": isDesktop,
                  })}
                >
                  {isTrendi ? (
                    <Trans i18nKey="login">I already have an Account</Trans>
                  ) : (
                    <Trans i18nKey="login">Login</Trans>
                  )}
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </form>
  );
}

import React from "react";
import { css, useTheme } from "@emotion/react";
import { Trans } from "react-i18next";
import { useAppSelector } from "redux/Store";
import { ReactComponent as Clock } from "assets/images/icon-clock2.svg";
import { ReactComponent as JoystickIcon } from "assets/images/prize-pool.svg";
import { ReactComponent as NiuClock } from "assets/clients/Niu/Clock.svg";
import { ReactComponent as NiuTrophy } from "assets/clients/Niu/Trophy.svg";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import GTimer from "../../../common/GTimer";
import EntryFee from "../../../common/EntryFee";
import { isHTML, slugifyStringForTranslation } from "utils";
import { isBarca, isNiu } from "utils/applicationSlug";
import { AiOutlineTrophy, AiOutlineClockCircle } from "react-icons/ai";
import { UserIcon } from "utils/Icons";
import { cleanString } from "utils/textParser";

const PrizesSliderItem = ({
  tournament,
  onNext,
  onPrevious,
  showNext,
  showPrevious,
}: {
  tournament: ITournament;
  showNext: boolean;
  showPrevious: boolean;
  game: IGame;
  onNext: () => void;
  onPrevious: () => void;
}) => {
  const theme: ITheme = useTheme();
  const { application } = useAppSelector((state: any) => state.common);
  const {
    // participant_display_type,
    participant_count,
    max_participant,
    subtitle,
    list_subtitle,
    prize_calculation,
    entry_fee_type,
  } = tournament;
  const showButtons = false;

  const currentListSubtitle = () => {
    if (isHTML(list_subtitle)) {
      return <span dangerouslySetInnerHTML={{ __html: list_subtitle }} />;
    }

    return <Trans i18nKey="tournament-subt">{list_subtitle}</Trans>;
  };

  const renderListSubtitle = () => {
    if (isHTML(list_subtitle)) {
      return <span dangerouslySetInnerHTML={{ __html: list_subtitle }} />;
    }
    return <Trans i18nKey={slugifyStringForTranslation(cleanString(list_subtitle))}>{list_subtitle}</Trans>;
  };

  const renderSubtitle = () => {
    if (isHTML(subtitle)) {
      return <span dangerouslySetInnerHTML={{ __html: subtitle }} />;
    }
    return <Trans i18nKey={slugifyStringForTranslation(cleanString(subtitle))}>{subtitle}</Trans>;
  };

  return (
    <div css={baseCss} className={`prizes-slider-item ${application?.slug}`}>
      <div className="upper-div d-flex flex-row justify-content-center align-items-center py-2">
        {(showPrevious && showButtons && (
          <div className="btn-arrow" onClick={onPrevious}>
            <FaChevronLeft />
          </div>
        )) || <div />}
        <div className="px-5 title-md title-header">
          <b>{renderSubtitle()}</b>
        </div>
        {(showNext && showButtons && (
          <div className="btn-arrow" onClick={onNext}>
            <FaChevronRight />
          </div>
        )) || <div />}
      </div>
      <div className="lower-div">
        <div className="d-flex flex-row justify-content-between lower-div-items">
          {isNiu ? (
            <>
              <div className="lower-div-item">
                <div className="d-flex justify-content-center py-2">
                  <div className="icon">
                    <UserIcon fill={theme.text.secondary} />
                  </div>
                </div>
                <div>
                  <small className="lower-div-title title-xs">
                    <Trans i18nKey="players">Players</Trans>
                  </small>
                </div>
                <div className="lower-div-footer body-md">
                  {participant_count}
                  {max_participant > 0 && `/${max_participant}`}
                </div>
              </div>
              <div className="lower-div-item">
                <div className="d-flex justify-content-center py-2">
                  <div className="icon">
                    <NiuTrophy fill={theme.text.secondary} />
                  </div>
                </div>
                <div>
                  <small className="lower-div-title title-xs">
                    <Trans i18nKey="prize-pool">Prize Pool</Trans>
                  </small>
                </div>
                <div className="lower-div-footer body-md">
                  {(prize_calculation === "manual" && currentListSubtitle()) || (
                    <EntryFee
                      payMethod={entry_fee_type}
                      entry_fee={tournament?.prize_pool}
                      tournamentFeeCurrency={tournament?.entry_fee_currency}
                    />
                  )}
                </div>
              </div>

              <div className="lower-div-item">
                <div className="d-flex justify-content-center py-2">
                  <div className="icon">
                    <NiuClock fill={theme.text.secondary} />
                  </div>
                </div>

                <div>
                  <small className="lower-div-title title-xs">
                    <Trans i18nKey="ends-in">Ends in</Trans>
                  </small>
                </div>

                <div className="lower-div-footer body-md">
                  {tournament?.end_time && (
                    <GTimer endTime={tournament.end_time} fields={["days", "hours", "minutes", "seconds"]} />
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="lower-div-item">
                <div>
                  <small className="lower-div-title title-xs">
                    <Trans i18nKey="prize-pool">Prize Pool</Trans>
                  </small>
                </div>
                <div className="d-flex justify-content-center py-2">
                  <div className="icon">
                    {isBarca ? <AiOutlineTrophy fill="white" /> : <JoystickIcon fill={theme.text.secondary} />}
                  </div>
                </div>
                <div className="lower-div-footer body-md">
                  {(prize_calculation === "manual" && <b>{renderListSubtitle()}</b>) || (
                    <b>
                      <EntryFee
                        payMethod={entry_fee_type}
                        entry_fee={tournament?.prize_pool}
                        tournamentFeeCurrency={tournament?.entry_fee_currency}
                      />
                    </b>
                  )}
                </div>
              </div>

              <div className="lower-div-item">
                <div>
                  <small className="lower-div-title title-xs">
                    <Trans i18nKey="players">Players</Trans>
                  </small>
                </div>
                <div className="d-flex justify-content-center py-2">
                  <div className="icon">
                    <UserIcon fill={isBarca ? "#ffffff" : theme.text.secondary} isPlayer={!isBarca} />
                  </div>
                </div>
                <div className="lower-div-footer body-md">
                  <b>
                    {participant_count}
                    {max_participant > 0 && `/${max_participant}`}
                  </b>
                </div>
              </div>

              <div className="lower-div-item">
                <div>
                  <small className="lower-div-title title-xs">
                    <Trans i18nKey="ends-in">Ends in</Trans>
                  </small>
                </div>

                <div className="d-flex justify-content-center py-2">
                  <div className="icon">
                    {isBarca ? <AiOutlineClockCircle fill="white" /> : <Clock fill={theme.text.secondary} />}
                  </div>
                </div>

                <div className="lower-div-footer body-md">
                  <b>
                    {tournament?.end_time && (
                      <GTimer endTime={tournament.end_time} fields={["days", "hours", "minutes", "seconds"]} />
                    )}
                  </b>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PrizesSliderItem;

const baseCss = (theme: ITheme) => css`
  @media (min-width: 300px) and (max-width: 320px) {
    /* width: 60px; */
    /* height: 60px; */
  }
  .btn-arrow {
    display: flex;
    align-items: center;
    padding: 2px;
    justify-content: center;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    box-shadow: 0 4px 5px #22222261;
  }
  &.prizes-slider-item {
    padding: 15px;
    background-color: ${theme.foreground.primary};
    border-radius: 15px;
    padding-bottom: 30px;
  }
  .upper-div {
    text-align: center;
    border-bottom: 1px solid #9e9e9e6b;
    margin-bottom: 5%;
    padding-bottom: 5% !important;
  }
  .lower-div-items {
    width: 100%;
    .lower-div-item {
      border-right: 1px solid #9e9e9e6b;
      :last-of-type {
        border-right: 0;
      }
      .lower-div-title {
        font-size: 0.7em;
        font-weight: 500;
      }
      .lower-div-footer {
        font-size: 0.73em;
      }
    }
    div {
      text-align: center;
      width: 100%;
      /* :last-of-type {
        border-right: 1px solid #9e9e9e4f;
      } */
      .icon {
        /* margin-top: 2px;
        margin-bottom: 2px;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background: #eceef0;
        display: inline-block; */
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        background: linear-gradient(270deg, #d5d3d3b8, #ffffff 122.67%);
        border-radius: 50%;
        width: 35px;
        height: 35px;
        box-shadow: -2px 3px 9px 0 rgb(120 135 182 / 30%);
        svg {
          fill: ${theme.button.primaryGradientStart};
          height: 100%;
          width: 60%;
        }
      }
    }
  }
  &.globe {
    .icon {
      svg {
        fill: #2274e5 !important;
      }
    }
  }
`;

import { css } from "@emotion/react";
import UBUNTU_BOLD from "assets/fonts/Croydon/Ubuntu-Bold.ttf";

const croydonStyles = (theme: ITheme) => css`
  @font-face {
    font-family: "Ubuntu Bold";
    src: local("Ubuntu Bold"), url(${UBUNTU_BOLD}) format("truetype");
  }

  &.croydon {
    * {
      font-family: "Ubuntu Bold" !important;
    }
    .custom-page {
      background-color: #000000;
    }
    .modal_container_2 {
      .title-md,
      .close {
        fill: #ffffff;
        color: #ffffff;
      }
    }
    .desktop-modal-content-body {
      .btn-sign-up,
      .btn-login {
        background: #ff8e00 !important;
      }
    }
    .common-modal-body {
      b {
        color: #ffffff;
      }
    }
    .board-container {
      .label-style {
        background: #d65c00;
      }
    }
    .tournament-list-item {
      .play-btn-browse {
        color: #ffffff;
      }
    }
    .history-container {
      .label-value {
        color: #000000;
      }
    }
    .voucher-modal-footer {
      .btn-apply-code {
        background: #ff8e00 !important;
      }
    }
    .registration-text {
      color: #ffffff !important;
    }

    .desktop-footer {
      &.croydon {
        .footer-content {
          background-color: #222220;
        }
        .link-img {
          path {
            fill: #ffffff;
          }
        }
        .link-label {
          color: #ffffff;
        }
      }
    }
    .profile-history-wrapper {
      .subtitle {
        color: #000000;
      }
    }
    .card-prize {
      .div-group {
        color: #000000;
      }
    }
    .show-more {
      color: #000000;
    }
    .rank-list-card {
      .list-heading {
        color: #ffffff !important;
      }
    }
    .faqCircleStyle {
      display: none;
    }
    .common-modal-body {
      b {
        color: #ffffff;
      }
    }
    .history-container {
      .label-value {
        color: #000000;
      }
    }
    .board-container {
      .label-style {
        background: #d65c00;
      }
    }
    .close_wrapper {
      .close {
        fill: #ffffff;
      }
    }
    .desktop {
      .gamename-style {
        color: #ffffff;
      }
      .rank-list-container {
        .label-no-ranks {
          color: #ffffff;
        }
      }
      .leaderboard-container {
        .reward-sty {
          color: #ffffff;
        }
        .faq-icon {
          fill: #ffffff !important;
        }
      }
      .drag-handle:after {
        background-color: #ffffff;
      }
      .close {
        fill: #ffffff;
      }
    }
    .leaderboard-container {
      .reward-sty {
        color: #000000;
      }
      .faq-icon {
        fill: #000000 !important;
      }
    }
    .avatar-update-modal {
      .avatarStyle {
        color: #000000;
      }
    }
    .history-container {
      .title-md {
        color: #ffffff;
      }
      .street-text {
        color: #000000 !important;
      }
    }
    .show-all-text {
      color: #ffffff;
    }
    .history-border {
      .list-group-item {
        color: #000000;
      }
    }
    .arcade-game-page {
      &.header-style {
        background-color: transparent;
      }
    }
    .arcade-game-page {
      .no-back-btn {
        .emptyDivCss {
          width: unset !important;
        }
      }
    }
    .croydon-game-page,
    .header-style,
    .faq-page,
    .desktop-layout,
    .header-color {
      background-color: transparent;
    }
    .drag-handle:after {
      background-color: #ffffff;
    }
    .show-all-text {
      color: #ffffff;
    }
    .header-line {
      color: #ffffff;
    }
    .text-style-transaction {
      color: #000000;
    }

    .leaderboard-wrapper {
      .close {
        fill: #ffffff;
      }
    }

    .leaderboard-popup-item {
      .close {
        fill: #000000;
      }
    }

    .label-sty {
      color: #000000;
    }
    .registration-text {
      @media (max-width: 1021px) {
        color: #000000;
      }
    }
    .dont-account {
      color: #ffffff;
    }
    .profile-history-wrapper {
      .back-ground {
        background-color: ${theme.background.primary};
      }
    }
    .avatarStyle {
      &.modal-title {
        color: #ffffff;
      }
    }
    .icon-container {
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      textarea:-webkit-autofill,
      textarea:-webkit-autofill:hover,
      textarea:-webkit-autofill:focus,
      select:-webkit-autofill,
      select:-webkit-autofill:hover,
      select:-webkit-autofill:focus {
        -webkit-text-fill-color: #ffffff;
        color: #ffffff;
        box-shadow: 0 0 0 1000px ${theme.background.primary} inset;
        transition: background-color 3000s ease-in-out 0s;
        background-color: ${theme.background.primary} !important;
      }
      .input-wrapper {
        input {
          color: #ffffff;
          font-size: 15px;
          &:focus {
            background-color: ${theme.background.primary};
          }
        }
      }
      .icon-right {
        svg {
          color: #ffffff;
        }
      }
      select {
        background-color: #6e6e6e;
        border-radius: 15px;
        color: #ffffff;
        &:focus {
          border: 0;
          box-shadow: none;
        }
      }
      .input-wrapper:focus-within input {
        background-color: ${theme.background.primary};
      }
    }
    .iframe-container {
      .latest-score-label {
        color: #ffffff;
      }
    }
    .browse-game-item {
      .game-name {
        color: #000000;
      }
    }
    .auth-btn {
      .button {
        background-color: #ff8e00 !important;
      }
      .button.button-link:hover {
        border: 1px solid #ffffff !important;
        color: #ffffff !important;
      }
    }
    .terms-condition-page {
      padding-bottom: 60px;
    }
    .label-text {
      a,
      button {
        color: #ff8e00 !important;
      }
    }
    .mobile-home-page {
      padding-top: 0 !important;
    }
    .top-bar-left {
      .right {
        justify-content: center;
      }
    }
    .top-bar-right {
      width: auto !important;
    }

    .game-timer-row,
    .participant-counter-row {
      margin-top: 0.313rem;
    }

    .text-play {
      background-color: #ff8e00 !important;
      width: 8.125rem !important;
    }

    .rank-btn,
    .text-play,
    .game-details {
      color: #ffffff !important;
      font-weight: 500;
    }

    .background-tile {
      filter: none !important;
    }

    .browse-games-rectangular-card {
      flex-direction: column-reverse;
      padding: 0.625rem 0;

      .participant-counter-row {
        display: none !important;
      }
    }

    .modal-content {
      background-color: #000000;
    }

    .half-style {
      background: #000000;
    }

    .close {
      color: #ffffff !important;
    }

    .auth-forget-password-page,
    .authentication,
    .page-login {
      background-color: #6e6e6e;

      .sign-up {
        color: #ff8e00;
      }

      input {
        background-color: #6e6e6e;

        &::placeholder {
          color: #ffffff;
        }
      }
    }

    .sub-button {
      background-color: #ff8e00 !important;
    }

    .mobile-footer-page {
      .text-center {
        color: #ffffff;
      }
    }

    .confirm-modal {
      .modal-title-2 {
        color: #000000;
      }
    }

    .pass {
      color: #ff8e00 !important;
    }

    .name-fields-container {
      flex-direction: column;

      div {
        width: 100%;
        padding: 0;
      }
    }

    .recommended-games-container {
      .mobile-view {
        background-color: #5900b7;
      }
    }

    .subtitle-text {
      color: #000000 !important;
    }

    .game-name {
      .text-marquee {
        width: 6.25rem !important;
        max-width: 6.25rem !important;
      }
    }

    .play-btn,
    .play-btn-browse,
    .btn-container,
    .btn-footer,
    .btn-style {
      background: none !important;
    }

    .triangle-button,
    .rank-btn,
    .play-btn,
    .play-btn-browse,
    .buy-token-style,
    .btn-container,
    .btn-footer,
    .btn-style {
      background-color: #ff8e00 !important;
    }

    .lower-div-items {
      div {
        .icon {
          svg {
            fill: #ff8e00 !important;
          }
        }
      }
    }

    .board-container {
      .tile {
        fill: #ff8e00 !important;
      }
    }

    .pesi-bg {
      border: 1px solid #ff8e00;
    }

    .progress-bar-container {
      .progress-bar {
        background-color: #ff8e00 !important;
      }
    }

    .header-container {
      margin-bottom: 1rem !important;
    }

    .browse-games-rectangular-card-top {
      img {
        border-radius: 0 !important;
      }
    }

    .desktop-play-button-backward {
      bottom: auto !important;
      top: 50% !important;
    }
    @media (max-width: 1021px) {
      .top-tournament-full-width {
        padding-top: 0;
      }
    }
  }
`;

export default croydonStyles;

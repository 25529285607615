import React, { useEffect } from "react";
import { LoginPanel } from "@bedrock_org/passport";
import { CommonModal } from "components/feature-specific/Modals/CommonModal";
import root from "react-shadow";
import styles from "@bedrock_org/passport/dist/style.css?inline";
import { css } from "@emotion/react";
import { CloseButton } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "redux/Store";
import { hideLoginPopup } from "redux/reducers/layoutReducer";
import LOGO from "assets/clients/go3/orange_horizontal.avif";
import { useSafeBedrockPassport } from "hooks/clients/useSafeBedrockPassport";
import { fetchCommonData } from "actions";
import { APP_SLUGS } from "constants/constants";

const OrangeLoginPopUp = () => {
  const { user: goamaUser } = useAppSelector(state => state.common);
  const dispatch = useAppDispatch();
  const { user } = useSafeBedrockPassport();
  const isGuestUser = goamaUser?.extra_data?.guest === true;
  // console.log({ user, goamaUser });
  useEffect(() => {
    if ((!goamaUser || isGuestUser) && user) {
      dispatch(
        fetchCommonData({
          app_slug: APP_SLUGS.GO3,
          country: "SG",
          userid: user?.id,
          name: user?.name,
          profile_pic: user?.photoUrl,
          extra_data: {
            ...user,
            profile_pic: user?.photoUrl,
          },
        }),
      );
    }
  }, [user, goamaUser, isGuestUser, dispatch]);
  return (
    <CommonModal
      style={css`
        .modal-content {
          background: black !important;
          padding: 0;
        }
      `}
      show
      hideCrossIcon
    >
      <div
        css={css`
          display: flex;
          width: 100%;
          justify-content: end;
          padding-right: 1rem;
        `}
      >
        <CloseButton
          variant="white"
          onClick={() => {
            dispatch(hideLoginPopup());
          }}
        />
      </div>
      <root.div>
        <style type="text/css">{styles}</style>
        <LoginPanel
          panelClass="bg-[#202020] text-white w-[380px] border-0 pb-0 shadow-none" //for the panel styling
          buttonClass="w-full hover:bg-orange-500/30" //will apply for all button atm
          // logo="https://irp.cdn-website.com/e81c109a/dms3rep/multi/orange-logo-v1a-20240228.svg"
          logo={LOGO}
          separatorText="OR"
          separatorTextClass="text-white bg-transparent"
          separatorClass="bg-[#202020]"
          //logo url
          showConnectWallet
          // showConnectWallet={!!user}
          titleClass="mr-1"
          title={(!user && "Sign in to\xa0") || ""} //title of the panel
          logoAlt="Orange Web3" //alt text for the logo
          logoClass="h-8 " //logo styling, example height width
        />
      </root.div>
    </CommonModal>
  );
};

export default OrangeLoginPopUp;

import React, { useCallback, useState } from "react";
import { css, useTheme } from "@emotion/react";
import { useAppSelector } from "redux/Store";
import cn from "classnames";
import { useLocation } from "react-router-dom";
import { useHistory } from "hooks/useHistory";
import { lighten } from "polished";
import { Trans } from "react-i18next";
import { isStringEqual } from "utils";
import UserProfileCustomization from "components/feature-specific/user/profile/UserProfileCustomization";
import { ReactComponent as BackArrow } from "assets/images/volver.svg";
import { ReactComponent as FoodPandBackArrow } from "assets/clients/foodpanda/back-button.svg";
import { ReactComponent as GoamaBackArrow } from "assets/images/icon-arrow-back.svg";
import { ReactComponent as CloseBtn } from "assets/images/close-icon.svg";
import { ReactComponent as NiuBackArrow } from "assets/clients/Niu/Back-Arrow.svg";
import { APP_SLUGS } from "constants/constants";
import RecommendedGamesModal from "components/feature-specific/Modals/RecommendedGamesModal/RecommendedGamesModal";
import Avatar from "components/feature-specific/avatar/desktop/Avatar";
import { isBonoxs, isClaro, isDibble, isGcash, isJeda, isNequi, isNiu } from "utils/applicationSlug";
import { ReactComponent as ClaroUser } from "assets/clients/Claro/User.svg";
import analytics from "utils/analytics";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NavContainer = ({ children, application, closeButton }: any) => (
  <div
    className={cn("d-flex col-12 position-relative navbar-container px-0", {
      "justify-content-end": closeButton,
      "justify-content-between align-items-center": !closeButton,
    })}
    css={css`
      min-height: 72px;
    `}
  >
    {children}
  </div>
);

const BackButton = ({ backButtonEnabled, onClick, color, closeButton }: any) => {
  const { isDesktop } = useAppSelector((state: any) => state.layout);
  const { application } = useAppSelector((state: any) => state.common);
  const theme: ITheme = useTheme();
  const history = useHistory();
  function getBackButtonBody() {
    if (closeButton) {
      return <CloseBtn fill={color || theme.text.primary} stroke={color || theme.text.primary} className="closebtn" />;
    } else if (isStringEqual(application?.slug, APP_SLUGS.FOODPANDA_APP)) {
      return (
        <>
          <FoodPandBackArrow />
          {history.location.pathname === `/tournament` && (
            <span className="foodpanda-title title-sm">
              <Trans i18nKey="play-to-win-pri">Play to win prizes</Trans>
            </span>
          )}
        </>
      );
    } else if (theme.layout === "BOXED") {
      return <BackArrow fill={theme.foreground.secondary} />;
    } else if (isNequi && history.location.pathname === `/tournament`) {
      return null;
    } else if (isNiu) {
      return (
        <>
          <NiuBackArrow fill={color || theme.text.primary} stroke={color || theme.text.primary} />
          <span className="go-back-text">Volver</span>
        </>
      );
    } else {
      return <GoamaBackArrow fill={color || theme.text.primary} stroke={color || theme.text.primary} />;
    }
  }

  if ((application?.is_global_back_button_enabled && backButtonEnabled) || closeButton) {
    return (
      <div
        tabIndex={0}
        role="button"
        onKeyDown={onClick}
        css={avatarStyle}
        className={cn(`d-flex flex-row align-items-center back-button-wrapper ${application?.slug}`, {
          "button-flex":
            (history.location.pathname === `/tournament` || history.location.pathname === `/tournament/missions`) &&
            !isDesktop,
        })}
        onClick={onClick}
      >
        {getBackButtonBody()}
      </div>
    );
  }
  return <div className="emptyDivCss" />;
};

interface INavbarProps {
  onBack?: Function;
  children?: any;
  avatarEnabled?: boolean;
  backButtonEnabled?: boolean;
  backButtonColor?: string;
  closeButtonEnabled?: boolean;
  forceHideBrandLogo?: boolean;
  config?: boolean;
  notifyData?: any;
  closeButton?: boolean; // only used in Arcade Play Game Page
}

export default function Navbar({
  children,
  avatarEnabled = true,
  backButtonEnabled,
  onBack,
  backButtonColor = undefined,
  config = true,
  notifyData,
  forceHideBrandLogo = false,
  closeButton = false,
}: INavbarProps) {
  const history = useHistory();
  const { isDesktop } = useAppSelector((state: any) => state.layout);
  const { pathname } = useLocation();
  const {
    user: commonUser,
    error: commonError,
    loading: commonLoading,
    application,
    user,
  } = useAppSelector((state: any) => state.common);
  const [showRecommendedGamesModal, setShowRecommendedGamesModal] = useState(false);

  const toggleRecommendedGamesModal = useCallback(
    () => setShowRecommendedGamesModal(!showRecommendedGamesModal),
    [showRecommendedGamesModal],
  );

  const hideBrandLogo = isGcash || isJeda;
  const handleBack = useCallback(
    (e: MouseEvent) => {
      if (history.location.pathname === `/C2PplayGamePage`) {
        if (!showRecommendedGamesModal) {
          toggleRecommendedGamesModal();
        }
      } else if (onBack) {
        onBack(e);
      } else if (history.location.pathname === `/tournament/my-games`) {
        history.push("/tournament");
      } else {
        history.goBack();
      }
    },
    [history, onBack, showRecommendedGamesModal, toggleRecommendedGamesModal],
  );

  if (commonUser && commonError && !commonLoading)
    return (
      <NavContainer closeButton={closeButton}>
        <BackButton onClick={handleBack} closeButton={closeButton} />
        <button> test </button>
      </NavContainer>
    );

  const renderAvatarContent = () => {
    if (isClaro) return null;

    let content;

    const isProfileEnabled = !application?.disable_profile;
    const validUser = user && config;

    if (isProfileEnabled && avatarEnabled && user) {
      content = <Avatar userExtraData={user?.extra_data} />;
    } else if ((isProfileEnabled && validUser) || (!avatarEnabled && validUser)) {
      content = <UserProfileCustomization notifyData={notifyData} />;
    } else {
      content = <div css={avatarStyle} className="emptyDivCss" />;
    }

    return content;
  };

  // const hideBackButtonInHomeForBonoxs = isBonoxs && (pathname === "/" || pathname === "/tournament");

  return (
    <div
      css={navWrapperStyle}
      className={cn({
        "has-back-btn col-12 px-2": application?.is_global_back_button_enabled && backButtonEnabled && !isDesktop,
        "no-back-btn": !(application?.is_global_back_button_enabled && backButtonEnabled),
        "has-back-btn px-2": isDesktop,
      })}
    >
      <NavContainer closeButton={closeButton}>
        <BackButton
          backButtonEnabled={backButtonEnabled}
          onClick={handleBack}
          closeButton={closeButton}
          color={backButtonColor}
        />

        <RecommendedGamesModal show={showRecommendedGamesModal} onHide={toggleRecommendedGamesModal} />

        {(application?.slug === APP_SLUGS.GLOBE || isDibble) &&
          application?.applicationsetting?.brand_logo &&
          (!forceHideBrandLogo || !hideBrandLogo) &&
          !isBonoxs && (
            <div className={`navbar-top-logo-2 ${application?.applicationsetting?.brand_logo_landing_page_position}`}>
              <img src={application?.applicationsetting?.brand_logo} alt="logo" />
            </div>
          )}

        <div
          className={cn(`button-group`, {
            "arcade-partner-logo": pathname === "/arcade",
          })}
        >
          {children}
        </div>

        {!isBonoxs && (
          <>
            {/* TODO: REFACTOR THIS CODE - assigned to @Rubel */}
            {!isDesktop && renderAvatarContent()}
          </>
        )}

        {isClaro && (
          <>
            <h1 className="goama-title">Goama</h1>
            <button
              className="profile-button"
              onClick={() => {
                history.push("/profile");
                analytics.clickedProfileLink();
              }}
            >
              <ClaroUser />
            </button>
          </>
        )}
      </NavContainer>
    </div>
  );
}

const avatarStyle = (theme: ITheme) => css`
  .emptyDivCss {
    height: 35px;
    background-color: transparent;
  }
  &.back-button-wrapper {
    /* width: 100%; */
    /* flex-grow: 1;  */
  }
  &.globe {
    /* TODO: move this to globalStyles; done by RUBEL*/
    &.back-button-wrapper {
      width: unset;
    }
  }
  .avatarCss {
    position: relative;
    width: 35px;
    height: 35px;
    z-index: 1;
    cursor: pointer;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid ${theme.button.primaryGradientStart};
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    &.app-globe {
      border: 0;
    }
  }

  .razer .avatarCss {
    border: 0;
  }

  &.backButtonCss {
    width: 35px;
    height: 35px;
    z-index: 1;
    border: none;
    cursor: pointer;
    font-size: 14px;
    overflow: hidden;
    line-height: 37px;
    padding-right: 2px;
    text-align: center;
    border-radius: 50%;
    background-color: ${theme.foreground.primary};
    box-shadow: 0 1px 6px 3px ${lighten(0.7, theme.button.primaryShadow)};

    svg line {
      fill: ${theme.button.primaryGradientStart} !important;
      color: ${theme.button.primaryGradientStart} !important;
    }
  }

  &.closeButton {
    z-index: 1;
    background-color: ${theme.background.primary};
    color: ${theme.text.primary};
    border-radius: 50%;
    width: 40px !important;
    height: 40px;
    justify-content: center;
    align-items: center;
    margin-right: auto;
    svg {
      margin-right: 0 !important;
    }
  }

  .back-arrow {
    margin-right: 5px;
    cursor: pointer;
  }

  .back-font {
    color: ${lighten(0.1, theme.button.primaryGradientStart)} !important;
    font-size: 15px;
  }

  &.button-flex {
    flex-grow: 1;
  }

  .foodpanda-title {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0;
    text-align: left;
    margin-bottom: 0;
    margin-left: 20px;
  }

  .profile_avatar_frame {
    position: absolute;
    width: calc(100% * 1.25);
    height: calc(100% * 1.25);
    top: -5px;
    right: -5px;
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const navWrapperStyle = (theme: ITheme) => css`
  display: flex;
  /* width: calc(100% - 30px); */
  margin: 0 auto;
  .navbar-top-logo-2 img {
    max-width: 100px;
  }

  .arcade-partner-logo {
    /* width: calc(100% - 0); */
  }

  .TOP_LEFT {
    display: flex;
    width: 100%;
    justify-content: flex-start;
  }
  &.no-back-btn {
    .emptyDivCss {
      width: 100%;
    }
    .small-right-header-2 {
      /* padding-right: 0; */
    }
  }
  .button-group {
    display: inline-flex;
    /* min-width: 115px; */
  }

  .closebtn {
    width: 20px;
  }

  @media (max-width: 1021px) {
    &.has-back-btn {
      /* .text-header { flex-basis: 180%;} */
    }
    &.no-back-btn {
      .text-header {
        justify-content: center;
      }
    }
  }
`;

import React, { useEffect } from "react";
import { css } from "@emotion/react";
import modalLogo from "assets/clients/Ooredoo/ooredoo_logo.png";
import { ReactComponent as TickIcon } from "assets/images/subscription/tick_icon.svg";
import CommonSubscriptionModal from "../CommonSubscriptionModal";
import { useAppDispatch, useAppSelector } from "redux/Store";
import useModalHandler from "hooks/useModalHandler";
import { fetchSubscriptionUserInfo } from "redux/reducers/subscriptionReducer";
import { Trans } from "react-i18next";

const PaymentSuccessfulModalStyle = () => css`
  .modal-body-icon {
    height: 95px;
    width: 95px;
    border-radius: 50%;
    padding: 20px;
    background-color: #ed1c24;
    fill: white !important;
    box-shadow: 1px 3px 29px 0 rgba(150, 150, 150, 0.21);
    color: white;
    path {
      fill: white !important;
    }
  }
`;

export default function PaymentSuccessfulModal() {
  const { showPaymentSuccessModal } = useAppSelector((state: any) => state.modal);
  const { user_info, package_info } = useAppSelector((state: any) => state.subscription);
  const { closePaymentSuccessModal, closeSubscriptionModal } = useModalHandler();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchSubscriptionUserInfo(package_info?.slug));
  }, [dispatch, package_info?.slug]);

  return (
    <CommonSubscriptionModal
      show={showPaymentSuccessModal}
      onHide={closePaymentSuccessModal}
      title={
        <Trans i18nKey={user_info?.package?.name === "Weekly" ? "weekly-subscrip" : "monthly-subscri"}>
          Payment Successful
        </Trans>
      }
      logo={modalLogo}
      footer={
        <button
          onClick={() => {
            closePaymentSuccessModal();
            closeSubscriptionModal();
          }}
        >
          <Trans i18nKey="ok">Ok</Trans>
        </button>
      }
      style={PaymentSuccessfulModalStyle}
    >
      <div className="modal-body-container">
        <TickIcon className="modal-body-icon" />
      </div>
    </CommonSubscriptionModal>
  );
}

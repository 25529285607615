import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Nav, Modal, Tab } from "react-bootstrap";
import useModalHandler from "hooks/useModalHandler";
import fireIcon from "assets/images/subscription/fire_icon.svg";
import { ReactComponent as CloseIcon } from "assets/images/subscription/close_icon.svg";
import whatIcon from "assets/images/subscription/what_icon.svg";
import tickIcon from "assets/images/subscription/tick_icon.svg";
import backButtonIcon from "assets/images/subscription/back_button.svg";
import FaqAccordion from "./FaqAccordion";
import GoamaSubscriptionPaymentService from "services/payment/goama/GoamaSubscriptionPaymentService";
import MediumLoadingIndicator from "components/common/LoadingIndicator/MediumLoadingIndicator";
import { useAppSelector } from "redux/Store";
import { Trans } from "react-i18next";
import analytics from "utils/analytics";

export default function SubscriptionPackageModal() {
  const { package_info, user_info } = useAppSelector((state: any) => state.subscription);
  const { showSubscriptionModal } = useAppSelector((state: any) => state.modal);
  const { closeSubscriptionModal, openPaymentFailedModal, openPaymentSuccessModal, openUnsubscribeModal } =
    useModalHandler();
  const [faqEnable, setFaqEnable] = useState<boolean>(false);

  const activePackageName = user_info?.package?.name;
  const [active, setActive] = useState(user_info?.package?.name ? activePackageName : "Weekly");
  const [packageId, setPackageId] = useState();
  const [price, setPrice] = useState();
  const [saving, setSaving] = useState();

  const [benefitDescription, setBenefitDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const maxRetryAttempts = 5;

  useEffect(() => {
    const fetchData = () => {
      if (showSubscriptionModal) {
        analytics.viewedSubscriptionPopup({ active });
      }
      const activeInfo = package_info?.packages?.filter((item: any) => item.name === active);
      setBenefitDescription(activeInfo[0]?.benefits);
      setPackageId(activeInfo[0]?.id);
      setPrice(activeInfo[0]?.price_text);
      setSaving(activeInfo[0]?.saving_text);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, package_info?.packages]);

  const handleFaqModal = () => {
    setFaqEnable(true);
  };

  const handleSubscription = () => {
    setIsLoading(true);
    if (user_info && activePackageName) {
      const unsubscribe: any = new GoamaSubscriptionPaymentService();
      unsubscribe.UnsubscribeService();
      analytics.clickUnsubscribe({ active });
      setTimeout(() => {
        handleSubscriptionPayment();
      }, 2000);
    } else {
      handleSubscriptionPayment();
    }
  };

  const handleSubscriptionPayment = async () => {
    setIsLoading(true);
    const paymentService = new GoamaSubscriptionPaymentService();
    try {
      const res: any = await paymentService.initiatePayment({
        subscription: {
          config_slug: package_info?.slug,
          package_id: packageId,
        },
      });

      const subscription_status = res.status;

      if (subscription_status === "active") {
        analytics.clickSubscribed({ subscription_status, active });
        openPaymentSuccessModal();
      } else if (subscription_status === "failed" || subscription_status === "cancelled") {
        analytics.clickSubscribed({ subscription_status, active });
        openPaymentFailedModal();
      } else if (subscription_status === "pending") {
        await retrySubscriptionPayment(paymentService, 0);
        analytics.clickSubscribed({ subscription_status, active });
      }
    } catch (error) {
      openPaymentFailedModal();
    } finally {
      setIsLoading(false);
    }
  };

  const retrySubscriptionPayment = async (paymentService: any, retryCount: any) => {
    if (retryCount < maxRetryAttempts) {
      retryCount += 1;

      // Wait for 3 seconds before retrying
      await new Promise(resolve => setTimeout(resolve, 3000));

      await retrySubscriptionPayment(paymentService, retryCount);
    } else {
      openPaymentFailedModal();
    }
  };

  const benefits = benefitDescription.split("\r\n");
  const benefitList = benefits.map((benefit, index) => <li key={index}>{benefit}</li>);

  const modalBody = () => {
    if (isLoading) {
      return <MediumLoadingIndicator height={350} />;
    } else {
      return !faqEnable ? (
        <>
          <div className="modal-title text-center px-3">
            <h6>{package_info?.title}</h6>
          </div>
          <div className="modal-tabs mt-4 pt-2">
            <Tab.Container defaultActiveKey={active}>
              <div className="tabs-bar text-center">
                <Nav className="nav-tabs">
                  <button className="what-icon" onClick={handleFaqModal}>
                    <span>
                      <img src={whatIcon} alt="info" />
                    </span>
                  </button>

                  {package_info?.packages?.map((data: any, index: number) => (
                    <Nav.Item
                      key={index}
                      onClick={() => {
                        setActive(data?.name);
                        setBenefitDescription(data?.benefits);
                        setPackageId(data?.id);
                        setPrice(data?.price_text);
                        setSaving(data?.saving_text);
                      }}
                    >
                      {data?.is_best_deal && (
                        <img src={fireIcon} alt="fire" className="fire-icon" onClick={e => e.stopPropagation()} />
                      )}
                      <Nav.Link eventKey={data?.name}>{data?.name}</Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </div>
              <Tab.Content>
                <Tab.Pane eventKey={active}>
                  <div className="package-features mt-4">
                    {saving === null ? (
                      <div className="badge-one centered">
                        <p>{price}</p>
                      </div>
                    ) : (
                      <div className="feature-badge-wrapper">
                        <div className="badge-one">
                          <p>{price}</p>
                        </div>
                        <div className="badge-two">
                          <p>{saving}</p>
                        </div>
                      </div>
                    )}
                    <ul className="benefit-list">{benefitList}</ul>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </>
      ) : (
        <FaqAccordion />
      );
    }
  };

  return (
    <Modal
      show={showSubscriptionModal}
      onHide={closeSubscriptionModal}
      css={subscriptionModalStyle}
      centered
      className="subs-modal"
    >
      <div className="modal-header">
        <div className="header-top d-flex justify-content-center">
          {faqEnable ? (
            <button className="close-btn" onClick={() => setFaqEnable(false)}>
              <img src={backButtonIcon} alt="close" />
            </button>
          ) : (
            <button className="close-btn" onClick={closeSubscriptionModal}>
              {/* <img src={closeIcon} alt="close" /> */}
              <CloseIcon className="subs-close-icon" />
            </button>
          )}
          <img src={package_info?.logo} alt="logo" width="50%" />
        </div>
      </div>
      <Modal.Body>{modalBody()}</Modal.Body>
      {!faqEnable && !isLoading && (
        <Modal.Footer className="pb-4 ">
          <div className="btn-group">
            <button
              disabled={active === activePackageName}
              onClick={handleSubscription}
              className={active === activePackageName ? "modal-footer-btn disabled" : "modal-footer-btn me-3"}
            >
              <Trans i18nKey={active === activePackageName ? "current-package" : "ooredoo-account"}>
                {active == activePackageName ? "Current Package" : "Ooredoo Account"}
              </Trans>
            </button>
            {active !== activePackageName && (
              <button disabled className="modal-footer-btn disabled">
                Mpitesan
              </button>
            )}
          </div>
        </Modal.Footer>
      )}
      {user_info.length !== 0 && (
        <p onClick={openUnsubscribeModal} className="unsubscribe">
          <Trans i18nKey="unsubscribe">Unsubscribe</Trans>
        </p>
      )}
    </Modal>
  );
}

const subscriptionModalStyle = (/*theme: ITheme */) => css`
  .modal-content {
    border-radius: 10px;
    background: #fff;
    box-shadow: 1px 3px 29px 0 rgba(150, 150, 150, 0.21);
    min-height: 400px;
    margin: 0 auto;
  }
  .modal-header {
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 0;
    border-bottom: 0;
    .close-btn {
      position: absolute;
      top: 5px;
      left: 10px;
      background: transparent;
      border: 0;
      cursor: pointer;
    }
  }

  /* .header-top {
    margin-right: 40px;
  } */

  .modal-title {
    h6 {
      font-size: 1.125rem;
      color: #ed1c24;

      /* span {
        color: #ed1c24;
      } */
    }
  }
  .modal-footer {
    justify-content: center;
    border-top: 0;
    .modal-footer-btn {
      min-height: 43px;
      color: #ffffff;
      background-color: #ed1c24;
      border: 0;
      border-radius: 21px;
      box-shadow: 0 9px 10px 0 rgba(231, 102, 102, 0.35);
      font-size: 1.125rem;
      padding: 5px 10px;
      transition: transform 0.2s ease-in-out;
      &.disabled {
        background: #8f8f8f;
        pointer-events: none;
        box-shadow: none;
      }
      &:active {
        outline: none;
        transform: scale(0.91);
      }
      @media (min-width: 320px) {
        font-size: 1rem;
      }
    }
  }
  .modal-tabs {
    font-size: 1rem;
    justify-content: center;
    .nav-link {
      padding: 0.5rem;
    }
    .nav-link.active {
      background-color: #ed1c24;
      color: #ffffff;
    }
    .nav-link {
      color: #ed1c24;
      border-bottom: 0;
      border-radius: 14px;
    }
    .nav-current-package {
      background-color: #6f6c6d !important;
    }
    .nav-tabs {
      display: inline-flex;
      position: relative;
      box-shadow: 0 4px 4px 0 rgba(231, 102, 102, 0.25);
      border-radius: 14px;
      border-bottom: 0;
      justify-content: space-between;
      background: rgba(247, 247, 247, 1);
    }
    .nav-item {
      position: relative;
      min-height: 40px;
      min-width: 90px;
      .fire-icon {
        position: absolute;
        top: -10px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 9999;
      }

      @media (min-width: 375px) {
        min-width: 100px;
      }
    }
    .what-icon {
      position: absolute;
      top: -35px;
      right: 0;
      padding: 0;
      border: 0;
      background: transparent;
    }
    .package-features {
      display: flex;
      position: relative;
      align-items: center;
      font-size: 18px;
      border: 1px solid #ed1c24;
      box-shadow: 0 4px 4px 0 rgba(231, 102, 102, 0.35);
      border-radius: 24px;
      min-height: 170px;
      margin: 0 auto;
      max-width: 100%;
      @media (min-width: 375px) {
        max-width: 80%;
      }
    }
    .feature-badge-wrapper {
      position: absolute;
      display: flex;
      justify-content: space-between;
      left: 24%;
      top: -11px;
    }

    .badge-one {
      &.centered {
        position: absolute;
        top: -9px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .badge-one,
    .badge-two {
      box-shadow: 0 4.451391220092773px 4.451391220092773px 0 rgba(0, 0, 0, 0.25);
      background-color: #363636;
      border-radius: 7px;
      padding: 0 6px 2px 6px;
      p {
        margin-bottom: 0;
        font-size: 0.669rem;
        color: #ffffff;
      }
    }
    .badge-two {
      margin-left: 30px;
    }
  }
  .benefit-list {
    list-style-image: url("${tickIcon}");
    color: #949494;
  }
  .unsubscribe {
    color: #b6b6b6;
    font-size: 15px;
    text-decoration: underline;
    font-weight: 400;
    text-align: center;
  }
`;

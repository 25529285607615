import React, { useEffect } from "react";
import { css } from "@emotion/react";
import { useAppDispatch, useAppSelector } from "redux/Store";
import { showEarnMoreSilverDialog } from "providers/client-sdk-providers/razer";
import Score_Summary_Icon from "assets/images/memepop/score_summary_icon.png";
import cn from "classnames";
// import api from "actions/api";
import { fetchMemepopScoreSummary } from "redux/reducers/tokenReducer";

interface IPlusButtonProps {
  plusButton?: boolean;
}

export default function MemepopScoreSummary({ plusButton = true }: IPlusButtonProps) {
  const dispatch = useAppDispatch();
  const { application, user } = useAppSelector(state => state.common);
  const { memepopScoreSummary } = useAppSelector((state: any) => state.walletToken);

  useEffect(() => {
    dispatch(fetchMemepopScoreSummary());
    // const fetchScoreSummary = () => {
    //   api
    //     .get("/tournaments/score-summary/")
    //     .then(response => {
    //       setScoreSummary(response.data.data.total_score); // Adjust key as per API response
    //     })
    //     .catch(error => {
    //       console.error("Failed to fetch score summary:", error);
    //       setScoreSummary(null);
    //     });
    // };

    // fetchScoreSummary();
  }, [dispatch, user]);

  return (
    <div>
      {memepopScoreSummary === null ? (
        <span>Loading...</span>
      ) : (
        <div
          tabIndex={0}
          role="button"
          aria-label="Show Earn More Silver Dialog"
          css={link}
          className={cn("right-style razer-silver-status", {
            "game-list-topbar":
              (!application?.is_back_button_enabled || !application?.enable_top_bar) &&
              application?.applicationsetting?.layout_type === "REGULAR",
          })}
          onClick={showEarnMoreSilverDialog}
          onKeyDown={showEarnMoreSilverDialog}
        >
          <div className={plusButton ? "d-flex flex-row" : "upper"} css={plusButton ? backgroundStyle : tokenStyle}>
            <div className="d-flex flex-row justify-content-center align-items-center back-design pe-1 me-1 ms-1">
              <img
                src={Score_Summary_Icon}
                alt="ticket"
                css={css`
                  width: 20px;
                `}
              />
              <span className="label-sty ps-1">{memepopScoreSummary || 0}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

// Styles remain unchanged

const backgroundStyle = () => css`
  border-bottom-left-radius: 130px;
  border-top-left-radius: 130px;
  border-top-right-radius: 130px;
  border-bottom-right-radius: 130px;
  height: 35px;
  width: 100px;
  justify-content: space-between;
  cursor: pointer;
  background-color: aliceblue;
  box-shadow: -4px 9px 8px -7px #b09450;
  margin-right: 5px;

  @media screen and (max-width: 350px) {
    height: 35px;
    width: 90px;
  }

  @media screen and (min-width: 1022px) {
    height: 40px;
  }

  .label-sty {
    color: #332927;
    font-size: 15px;

    @media screen and (min-width: 1022px) {
      font-size: 15px;
    }
  }
`;

const tokenStyle = () => css`
  position: relative;
  display: flex;
  border-radius: 130px;
  height: 35px;
  cursor: pointer;

  .token-radius {
    border-radius: 50%;
    width: 29px;
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const link = () => css`
  border: none;
  text-decoration: none;
  display: inline-block;

  &.right-style {
    right: 0;
    min-width: 100px;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    outline: none;
  }

  .label-sty {
    color: #3ccff5;
    font-size: 15px;
    font-weight: 600;
  }
`;

import { createSlice } from "@reduxjs/toolkit";

// import paymentAPI from "actions/api/payments";
// import balanceAPI from "actions/api/balance";
import api from "actions/api";
import { AppDispatch } from "redux/Store";

export interface IToken {
  data: any[];
  balance: any[];
  memepopScoreSummary: any[];
  loading: boolean;
  error: boolean;
}

const initialState: IToken = {
  data: [],
  balance: [],
  memepopScoreSummary: [],
  loading: false,
  error: false,
};

const tokenSlice = createSlice({
  name: "walletToken",
  initialState,
  reducers: {
    fetchTokenHistoryLoading(state) {
      state.loading = true;
    },
    fetchTokenHistorySuccess(state, action) {
      state.data = action.payload;
      state.loading = false;
      state.error = false;
    },
    fetchTokenHistoryError(state) {
      state.data = [];
      state.loading = false;
      state.error = true;
    },
    fetchTokenBalanceLoading(state) {
      state.loading = true;
    },
    fetchTokenBalanceSuccess(state, action) {
      state.balance = action.payload;
      state.loading = false;
      state.error = false;
    },
    fetchTokenBalanceError(state) {
      state.balance = [];
      state.loading = false;
      state.error = true;
    },

    fetchMemepopScoreSummaryLoading(state) {
      state.loading = true;
    },
    fetchMemepopScoreSummarySuccess(state, action) {
      state.memepopScoreSummary = action.payload.data.total_score;
      state.loading = false;
      state.error = false;
    },
    fetchMemepopScoreSummaryError(state) {
      state.memepopScoreSummary = [];
      state.loading = false;
      state.error = true;
    },
  },
});

export const {
  fetchTokenHistoryError,
  fetchTokenHistoryLoading,
  fetchTokenHistorySuccess,
  fetchTokenBalanceLoading,
  fetchTokenBalanceSuccess,
  fetchTokenBalanceError,
  fetchMemepopScoreSummaryLoading,
  fetchMemepopScoreSummarySuccess,
  fetchMemepopScoreSummaryError,
} = tokenSlice.actions;

export default tokenSlice.reducer;

export const fetchTokenHistory = (country: string) => async (dispatch: AppDispatch) => {
  dispatch(fetchTokenHistoryLoading());
  return api
    .get(`payments/token/history/`, { params: { country } })
    .then(response => {
      dispatch(fetchTokenHistorySuccess(response.data));
      return response.data;
    })
    .catch(() => dispatch(fetchTokenHistoryError()));
};

export const fetchTokenBalance = (country: string | null) => async (dispatch: AppDispatch) => {
  if (!country) return;
  dispatch(fetchTokenBalanceLoading());
  return api
    .get(`balance/${country}/`)
    .then(response => {
      dispatch(fetchTokenBalanceSuccess(response.data));
      return response.data;
    })
    .catch(() => dispatch(fetchTokenBalanceError()));
};

export const fetchMemepopScoreSummary = () => async (dispatch: AppDispatch) => {
  dispatch(fetchMemepopScoreSummaryLoading());
  return api
    .get(`/tournaments/score-summary/`)
    .then(response => {
      dispatch(fetchMemepopScoreSummarySuccess(response.data));
      return response.data;
    })
    .catch(() => dispatch(fetchMemepopScoreSummaryError()));
};

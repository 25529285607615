import { css } from "@emotion/react";

const aliPlusStyles = css`
  &.danaaliplus,
  &.tngaliplus {
    .half-style {
      width: 100vw;
      background: transparent linear-gradient(311deg, #3560e3 0%, #3d6de6 34%, #0da0fd 100%) 0% 0% no-repeat padding-box !important;
      .latest-score {
        font-size: 2em;
      }
    }
    .progress-share-modal_survery-card-list-container {
      margin-top: 2px !important;
    }
    .campaign-message-modal {
      .modal-body {
        border-radius: 15px;
      }
      .title {
        color: #1479f7;
      }
    }
    .campaign-message-modal-footer {
      border-radius: 15px;
      .campaign-message-modal-footer-button {
        /* background: #1479f7 0% 0% no-repeat padding-box; */
        box-shadow: 0 15px 10px #1479f740;
      }
    }
    .browse-games-container {
      background: #f6f6f6 0% 0% no-repeat padding-box;
    }
    .card-win-prizes {
      &.mobile-view {
        background: transparent linear-gradient(90deg, #ee5b65 0%, #d41461 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0 15px 15px #f579254d;
      }
    }
    .second-half {
      .btn-replay {
        background: #1479f7 0% 0% no-repeat padding-box;
        border: 1px solid #ffffff;
      }
      .button-bar {
        .btn-container {
          box-shadow: 0 12px 17px -7px #f00064;
          border: none;
          &.btn-home,
          &.btn-leaderboard,
          &.btn-share {
            background: #ffffff 0% 0% no-repeat padding-box;
            svg {
              fill: #f00064;
            }
          }
        }
      }
    }
  }
`;

export default aliPlusStyles;

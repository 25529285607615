import { css } from "@emotion/react";

const tngStyles = css`
  &.tng {
    .header-text {
      color: #ffffff !important;
    }

    .page-wrapper {
      .second-part {
        span {
          color: #ffffff !important;
        }
        .header-style {
          color: #ffffff !important;
        }
      }

      .container {
        span {
          color: #ffffff !important;
        }

        .ticket-history-body {
          .header-style {
            color: #ffffff !important;
          }
        }
      }

      .prize-amount {
        span {
          color: #000000 !important;
        }
      }
    }

    .ticket-info-title,
    .ticket-info-container,
    .fontHeadStyle,
    .fontSubStyle,
    .tournament-history-btn,
    .stats-empty {
      color: #ffffff !important;
    }

    .back-arrow {
      fill: #ffffff !important;
      stroke: #ffffff !important;
    }

    .modal_container_2 {
      .contain-style {
        color: #ffffff !important;
      }
    }

    .name-sty {
      color: #000000 !important;
    }

    .section-header,
    .no-info,
    .not-active-button {
      color: #ffffff !important;
    }

    .activated-button {
      color: #c16eff !important;
    }
  }
`;

export default tngStyles;

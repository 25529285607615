import { css } from "@emotion/react";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const paobcStyles = (theme: ITheme) => css`
  &.paobc {
    .progress-bar-container .progress-bar {
      box-shadow: none !important;
    }
    .progress-share-overlay {
      .brand-logo {
        gap: 0.5rem;
        z-index: -1;
        padding: 0 !important;
        > img:first-of-type {
          padding: 0 !important;
          width: 20% !important;
        }

        img:nth-of-type(2) {
          width: 15% !important;
          margin-bottom: 2px !important;
          padding: 0 !important;
        }
      }
      .half-style {
        background: #006c39;
        padding-bottom: 0;
        margin-bottom: 0 !important;
        .second-half {
          .btn-leaderboard-wrapper {
            margin-right: 0 !important;
            margin-bottom: 1rem !important;
          }
          .btn-container {
            margin-bottom: 0;
            background: #307746;
            border: none;
            box-shadow: #1e482b 0 12px 17px -7px;
          }
        }
        .replay-button {
          bottom: -2rem !important;
        }
        .player-rank-board {
          .first-board,
          .middle-board,
          .last-board {
            svg {
              fill: #307746;
            }
            .label-style {
              background: #1e482b;
            }
          }
        }
      }
      .recommended-games-container {
        display: none;
      }
    }
  }
`;

export default paobcStyles;

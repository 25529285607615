import React from "react";
import { css } from "@emotion/react";
import { rgba } from "polished";
import Modal from "react-bootstrap/Modal";
import classNames from "classnames";

const modalStyle = (theme: ITheme) => css`
  /* color: ${theme.text.primary}; */
  /* background-color: ${theme.background.primary}; */
  background: transparent;

  &.modal {
    /* z-index: 10999; */
  }

  &.header {
    font-size: 18px;
    border: none;
    button.close {
      color: ${theme.text.primary};
      text-shadow: none;
    }
  }

  .modal-content {
    padding: 24px;
    border-radius: 16px;
  }

  .modal-header {
    padding: 0;
    margin-bottom: 4px;
    border: 0;
    height: 100%;
    .close {
      padding: 0;
      padding-top: 10px;
      padding-right: 16px;
      opacity: 1;
      font-size: 32px;
      font-weight: normal;
    }
  }

  .modal-body {
    padding: 0;
    margin-top: 16px;
    margin-bottom: 24px;

    .common-modal-body {
      height: 100%;
    }
  }

  .modal-footer {
    padding: 0;
    display: inline;
    > div {
      margin: 0;
      display: flex;
      justify-content: space-between;
      button,
      a {
        padding: 0;
        border-radius: 8px !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        height: 54px;
        width: 100%;
        @media (min-width: 320px) and (max-width: 374px) {
          width: 45%;
        }
      }
    }
  }

  .exhausted-btn {
    @media (min-width: 320px) and (max-width: 374px) {
      width: 100% !important;
    }
  }

  .modal-btn-block {
    background-color: ${theme.button.primaryGradientStart} !important;
    border: none !important;
    color: ${theme.background.primary};
    box-shadow: 0 11px 10px ${rgba(theme.button.primaryGradientStart, 0.3)};
  }

  &.loading {
    .close {
      opacity: 0.5;
    }
  }

  .close {
    &:focus {
      outline: none;
    }
  }
`;

export const CommonModal = ({
  show = false,
  onHide,
  children,
  header = null,
  footer = null,
  style,
  loading = false,
  hideCrossIcon = false,
  ...domProps
}: any) => {
  const closeModal = () => {
    onHide();
  };

  return (
    <Modal
      style={style}
      onHide={() => !loading && closeModal()}
      show={show}
      backdrop="static"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      css={[modalStyle, style]}
      {...domProps}
      className={classNames("common-modal", {
        loading: loading,
      })}
      restoreFocus
    >
      <Modal.Header closeButton={!hideCrossIcon} className="header title-md">
        <b className="header_title">{header}</b>
      </Modal.Header>
      <Modal.Body className="body">
        <div className="common-modal-body">{children}</div>
      </Modal.Body>
      <Modal.Footer className="footer" style={{ borderTop: "none" }}>
        {footer}
      </Modal.Footer>
    </Modal>
  );
};

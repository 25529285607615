import { css } from "@emotion/react";
import SPEEDEE_REGULAR from "assets/fonts/Mcdonalds/Speedee_Rg.ttf";
import SPEEDEE_BOLD from "assets/fonts/Mcdonalds/SpeedeeCd_Bd.ttf";

const mcDonaldsStyles = css`
  @font-face {
    font-family: "Speedee";
    src: local("Speedee"), url(${SPEEDEE_REGULAR}) format("truetype");
    font-weight: normal;
  }

  @font-face {
    font-family: "SpeedeeBold";
    src: local("SpeedeeBold"), url(${SPEEDEE_BOLD}) format("truetype");
    font-weight: normal;
  }

  &.mcdonalds {
    * {
      font-family: "Speedee";
      font-weight: 400;
      -webkit-font-smoothing: antialiased;
    }
    .title-lg,
    .title-md,
    .title-sm,
    .title-xs {
      font-family: "Speedee";
    }
    .title-lg,
    .title-md {
      font-weight: 600;
    }
    .modal-content {
      .text-header {
        font-family: "SpeedeeBold";
      }
    }
    .progress-share-overlay {
      .half-style {
        background: #ffbc0d;
      }
      .btn-container {
        box-shadow: 0 12px 17px -7px rgb(98, 95, 28);
        -webkit-box-shadow: 0 12px 17px -7px rgb(98, 95, 28);
      }
    }
    .board-container {
      .tile {
        fill: #db0007;
      }
    }
    .board-container {
      .label-style {
        background: rgba(0, 0, 0, 0.15);
      }
    }
    .card-win-prizes {
      background: linear-gradient(90deg, #ff0000 50%, #db0007 100%);
    }
    .card-win-prizes-text {
      padding-left: 30px !important;
    }
    .head {
      &.header-color {
        background-color: transparent;
      }
    }
    .header-position {
      &.header-sty {
        background-color: transparent;
      }
      &.background-sty {
        background-color: transparent;
      }
    }
    .homepage-background {
      box-shadow: none;
      background-color: #ffbc0d;
      border: 1px solid rgb(218 15 22);
    }
    .rank-button {
      background-color: #ffffff;
      color: #000000 !important;
      font-weight: 700;
      box-shadow: 0 14px 20px 1px rgba(219, 0, 7, 0.15);
      margin-right: 1rem;
    }
    .upperdiv {
      background-color: #ffbc0d;
    }
    .token-status {
      background-color: rgb(139, 0, 240);
    }
    .top-bar-top {
      background: #ffbc0d;
      box-shadow: none;
    }
    .modal_header {
      color: #ffffff;
    }
    .modal_header,
    .modal_body,
    .browse-popup {
      background-color: #ffbc0d;
    }
    .drag-handle {
      &:after {
        background-color: #ffffff;
      }
    }
    .reward-sty,
    .list-heading {
      color: #ffffff !important;
    }
    .close {
      fill: #ffffff !important;
    }
    .browse-popup {
      .title-md {
        color: #ffffff;
      }
    }
    .leaderboard-container,
    .leaderboard-popup-item {
      background-color: #ffbc0d;
    }
    .modal_body {
      .btn-style {
        background: #db0007 !important;
      }
    }
    .play-button {
      background-color: #db0007 !important;
      color: #ffffff;
      font-weight: 700;
    }
    .first-part {
      background: #db0007;
      box-shadow: none;
    }
    .text-style-transaction {
      color: #000000;
    }
    .button-sty {
      justify-content: center !important;
    }
    .desktop-footer {
      /* display: none; */
    }
    .profile-history-wrapper {
      .back-ground {
        background-color: #db0007 !important;
      }
    }
    .rank-list-container {
      .label-no-ranks {
        color: #ffffff;
      }
    }
    .navbar-logos {
      position: absolute;
      left: 20px;
      top: 20px;
    }
    .rank-btn svg {
      fill: #db0007 !important;
    }
    .see-prizes {
      background-color: #db0007;
    }
    .second-half {
      .btn-container {
        background: #db0007;
        box-shadow: 0 12px 17px -7px #db0007;
      }
    }
    .triangle-button,
    .play-btn-browse {
      background: #db0007 !important;
    }
    .sidebar-container {
      .active-link {
        .link-img {
          fill: #db0007;
        }
      }
    }

    .progress_bottom_curve {
      background-color: #ffffff !important;
    }

    .active_progress_bottom_curve {
      background-color: #ffbc0d !important;
    }

    .single-game-card {
      .action_button {
        button {
          color: #ffbc0d !important;
        }
      }
    }

    .reward-code-container {
      .reward-code {
        .code {
          color: #ffffff !important;
        }
      }
    }

    .button-reward,
    .button-store {
      color: #ffffff !important;
    }
    .game-over-mission-wrapper {
      .missions__item {
        border: 1px solid #db0007;
      }
    }
  }
`;

export default mcDonaldsStyles;

import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import { createRoot } from "react-dom/client";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import i18n from "./I18n";
import store from "redux/Store";
import SdkProvider from "providers/client-sdk-providers";
import ThemeProvider from "providers/ThemeProvider";
import SocketProvider from "providers/SocketProvider";
import WindowSizeProvider from "providers/WindowSizeProvider";
import { Provider as RollbarProvider } from "@rollbar/react";
import { type Configuration } from "rollbar";
import "bootstrap/dist/css/bootstrap.min.css";
import "rc-slider/assets/index.css";
import "./index.css";
import { ClickToComponent } from "click-to-react-component";

import firebase from "firebase/compat/app";
import Main from "routes/main";
import history from "HistoryWrapper";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import { isGo3 } from "utils/applicationSlug";

// Firebase configuration
const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
};

firebase.initializeApp(firebaseConfig);

const rollbarConfig: Configuration = {
  accessToken: import.meta.env.VITE_ROLLBAR_POST_CLIENT_ITEM ?? "",
  captureUncaught: false,
  captureUnhandledRejections: false,
  environment: import.meta.env.MODE ?? "unknown",
  reportLevel: "error",
  autoInstrument: {
    log: false,
  },
  ignoredMessages: [
    "(unknown): Script error.",
    "Script error.",
    "TypeError: window.webViewJSBridge.onResume is not a function.",
    "window.webViewJSBridge.onResume is not a function.",
  ],
};

const manifestUrl = "https://go-games.gg/tonconnect-manifest.json";

// Lazy load BedrockPassportProvider
const LazyBedrockPassportProvider = React.lazy(() =>
  import("@bedrock_org/passport").then(module => ({
    default: module.BedrockPassportProvider,
  })),
);

export const Root = () => {
  const shouldLoadBedrock = isGo3(); // Check for your specific URL param

  // Dynamically assign the provider component
  const BedrockPassportProviderComponent = shouldLoadBedrock ? LazyBedrockPassportProvider : React.Fragment;

  return (
    <RollbarProvider config={rollbarConfig}>
      <Provider store={store}>
        <SdkProvider>
          <SocketProvider>
            <WindowSizeProvider>
              <ThemeProvider>
                <I18nextProvider i18n={i18n}>
                  <HistoryRouter history={history}>
                    <Suspense fallback={<div>Loading...</div>}>
                      <BedrockPassportProviderComponent
                        {...(shouldLoadBedrock && {
                          baseUrl: "https://passport.bedrockxr.com",
                          authCallbackUrl: `${window.location.origin}/auth/callback`,
                          tenantId: "orange",
                          walletConnectId: "479d164eb3edacc709e257fbcd05eca1",
                        })}
                      >
                        <TonConnectUIProvider manifestUrl={manifestUrl}>
                          <Main />
                          <ClickToComponent />
                        </TonConnectUIProvider>
                      </BedrockPassportProviderComponent>
                    </Suspense>
                  </HistoryRouter>
                </I18nextProvider>
              </ThemeProvider>
            </WindowSizeProvider>
          </SocketProvider>
        </SdkProvider>
      </Provider>
    </RollbarProvider>
  );
};

const container = document.getElementById("root");
if (!container) {
  throw new Error("The element #portal wasn't found");
}
const root = createRoot(container);

root.render(<Root />);

import React from "react";
import { css } from "@emotion/react";
import { ReactComponent as SpinWheelIcon } from "assets/images/wheel/spin-wheel.svg";
import { ReactComponent as Box } from "assets/images/wheel/box.svg";
import { fetchCampaign } from "actions";

import GTimer from "components/common/GTimer";
import { useAppDispatch, useAppSelector } from "redux/Store";

export default function SpinWheelBtn({ handleShow, fetchSpinWheelData }: any) {
  const { campaigns } = useAppSelector((state: any) => state.common) || { campaigns: null };
  const { campaign } = campaigns || {};
  const { minigamereward } = campaign || {};
  const endTime = minigamereward?.next_reset;
  const dispatch = useAppDispatch();

  return (
    <div css={spinBtnStyles}>
      <button onClick={handleShow} className="btn spin-btn">
        <div className="spin-btn-text">
          <span>Claim</span> <span>your</span> <br /> <Box />
          <span>Spin</span>
        </div>
        <SpinWheelIcon className="spin-svg " />
      </button>
      <div css={countDownStyle}>
        {!!endTime && (
          <GTimer
            onEnd={() => {
              dispatch(fetchCampaign());
              fetchSpinWheelData();
            }}
            expiredText="00:00:00:00"
            endTime={new Date(endTime).toISOString()}
            fields={["days", "hours", "minutes", "seconds"]}
            extendedDuration={true}
          />
        )}
      </div>
    </div>
  );
}

const spinBtnStyles = (theme: ITheme) => css`
  position: fixed;
  bottom: 65px;
  right: 15px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  .spin-btn {
    font-weight: 500;
    color: #${theme.button.buttonTextColor};
    padding: 0;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.1) 0 20px 25px -5px, rgba(0, 0, 0, 0.04) 0 10px 10px -5px;
    border-radius: 50%;
  }
  .spin-btn-text {
    position: absolute;
    top: -5px;
    left: -30px;
    line-height: 18px;
    text-wrap: nowrap;
    z-index: 99;
    span {
      font-family: "Spicy Rice", serif;
      font-weight: 600;
      font-style: normal;
      font-size: 22px;
      color: ${theme.button.primaryGradientStart};
      -webkit-text-stroke-width: 0.5px;
      -webkit-text-stroke-color: black;
      &:first-of-type {
      }
      &:nth-of-type(2) {
        font-size: 16px;
      }
      &:nth-of-type(3) {
        color: ${theme.button.tertiaryButtonBackground};
      }
    }
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
      animation-timing-function: ease-out;
    }
    100% {
      transform: rotate(360deg);
      animation-timing-function: ease-in;
    }
  }
  .spin-svg {
    animation: spin 4s linear infinite;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
`;
const countDownStyle = (theme: ITheme) => css`
  color: ${theme.text.tertiaryTextColor};
  text-align: center;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;
  max-width: 80px;
`;

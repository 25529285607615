import React, { useEffect, useState, useContext } from "react";
import { css, useTheme } from "@emotion/react";

import { useAppDispatch, useAppSelector } from "redux/Store";
import { Trans, useTranslation } from "react-i18next";
import { lighten } from "polished";
import cn from "classnames";

import RankListItem from "./RankListItem";
import DummyRankList from "./DummyRankList";
import MediumLoadingIndicator from "components/common/LoadingIndicator/MediumLoadingIndicator";

import { fetchTournamentRanking } from "redux/reducers/tournamentRankingReducer";
// import { fetchTournamentPrizes } from "redux/reducers/tournamentPrizesReducer";

import analytics from "utils/analytics";
import { SdkContext } from "providers/client-sdk-providers";
// import tournamentsAPI from "actions/api/tournaments";
import api from "actions/api";
import { isGo3 } from "utils/applicationSlug";
interface RankListProps {
  prizes?: any[];
  tournament: ITournament;
}

interface ParticipantData {
  participant: {
    extra_data: {
      name: string;
      profile_pic_id: number;
    };
    user_id: number;
  };
  rank: number;
  timestamp: string;
  value: number;
}

function isGuestName(data: ParticipantData): boolean {
  return data.participant.extra_data.name.startsWith("guest-");
}

function removeGuests(allRanks: ParticipantData[]): ParticipantData[] {
  return allRanks.filter(data => !isGuestName(data));
}

const RankList: React.FC<RankListProps> = ({ tournament }) => {
  const dispatch = useAppDispatch();
  const sdk = useContext(SdkContext);
  const theme: ITheme = useTheme();
  const { max_scores } = useAppSelector((state: any) => state.maxScore);
  const { leaderboard_setting } = tournament;
  const { application, user } = useAppSelector((state: any) => state.common);

  const { data: tournamentPrizes } = useAppSelector((state: any) => state.tournamentPrizes);
  const [currentUserRank, setCurrentUserRank] = useState<any>(null);

  const { tournamentRanks, loading } = useAppSelector((state: any) => state.ranking);
  const { t } = useTranslation("translation", { useSuspense: false });
  const prizes: any[] = tournamentPrizes[`${tournament?.id}`] ?? [];

  const displayPrize = prizes.length > 0;

  const [rankData, setRankData] = useState<any>({
    visibleRanks: [],
    remainingRanks: [],
    paginationSize: 10,
  });

  useEffect(() => {
    if (leaderboard_setting === "enabled") {
      dispatch(fetchTournamentRanking(tournament?.id, application?.applicationsetting?.enable_guest_user));
      // dispatch(fetchTournamentPrizes(tournament?.id));
    }
  }, [dispatch, tournament?.id, leaderboard_setting, application, user]);

  useEffect(() => {
    if (tournamentRanks[tournament?.id]) {
      let allRanks = tournamentRanks[tournament?.id];
      if (isGo3()) {
        allRanks = removeGuests(allRanks);
        allRanks = allRanks.map((data: ParticipantData, index: number) => {
          return {
            ...data,
            rank: index + 1,
          };
        });
      }
      const visibleRanks = allRanks.slice(0, rankData.paginationSize);
      const remainingRanks = allRanks.slice(rankData.paginationSize);
      setRankData((prevRankData: any) => ({
        ...prevRankData,
        visibleRanks,
        remainingRanks,
      }));
    }
  }, [tournamentRanks, rankData.paginationSize, tournament?.id]);

  useEffect(() => {
    if (user && tournamentRanks[tournament?.id]) {
      const userRank =
        tournamentRanks[tournament?.id]?.find((rank: any) => rank?.participant?.user_id === user?.id) || null;
      if (userRank) {
        setCurrentUserRank(userRank);
      } else {
        api
          .post(`tournaments/${tournament?.id}/user-rank/`, {
            "highest-score": tournament?.id && max_scores[tournament?.id],
          })
          .then((response: any) => {
            setCurrentUserRank(response.data);
          });
      }
    }
  }, [dispatch, tournament?.id, tournamentRanks, user, max_scores]);

  const loadMore = () => {
    const visibleRanks = [...rankData.visibleRanks, ...rankData.remainingRanks.slice(0, rankData.paginationSize)];
    const remainingRanks = rankData.remainingRanks.slice(rankData.paginationSize);
    setRankData({ ...rankData, visibleRanks, remainingRanks });
  };

  if (rankData.visibleRanks.length === 0 && loading) return <MediumLoadingIndicator height={250} />;

  return (
    <div
      css={rankListCss}
      className={cn(`rank-list-card ${application?.slug}`, {
        triangle: theme.layout === "TRIANGLE",
        boxed: theme.layout === "BOXED",
      })}
    >
      <div
        className={cn("row w-100 mx-auto list-heading", {
          "heading-border": rankData.visibleRanks.length === 0,
        })}
      >
        <div
          className="col-2 col-items text-start ps-2 title-xs"
          style={{
            maxWidth: "10%",
          }}
        >
          <Trans i18nKey="rank">Rank</Trans>
        </div>
        <div
          className="col col-items name-style  title-xs"
          style={{
            maxWidth: displayPrize ? "45%" : "100%",
          }}
        >
          <Trans i18nKey="name">Name</Trans>
        </div>
        {displayPrize && (
          <div
            className="col-3 col-items ps-2 text-start ps-3 title-xs"
            style={{
              maxWidth: "30%",
            }}
          >
            <Trans i18nKey="prize">Prize</Trans>
          </div>
        )}
        <div
          className="col-3 col-items text-end pe-3 title-xs"
          style={{
            maxWidth: "20%",
          }}
        >
          <Trans i18nKey="points">Points</Trans>
        </div>
      </div>
      {currentUserRank && (
        <div className="my-3 rank-list-container-first">
          <RankListItem
            tournament={tournament}
            score={currentUserRank}
            prizes={prizes}
            displayPrize={displayPrize}
            checkOwnRank={false}
            currentUser={true}
          />
        </div>
      )}

      {leaderboard_setting === "masked" && (
        <DummyRankList
          overlayBackgroundColor="#61149b"
          overlayText={t("please-play-pai", {
            defaultValue: "Please Play Paid Tournament to unlock full leaderboard",
          })}
        />
      )}

      {leaderboard_setting === "enabled" && (
        <ul className="ps-0 mb-0 rank-list-container">
          {rankData.visibleRanks.length > 0 ? (
            <>
              {rankData.visibleRanks.map((score: any, index: number) => (
                <RankListItem
                  tournament={tournament}
                  score={score}
                  key={index}
                  prizes={prizes}
                  displayPrize={displayPrize}
                  currentUser={false}
                />
              ))}
              {rankData.remainingRanks.length > 0 && (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                <li
                  className="list-group-item text-center py-2 show-more"
                  css={showMoreStyle}
                  onClick={() => {
                    loadMore();
                    analytics.clickedLoadMore();
                  }}
                >
                  <Trans i18nKey="show-more">Show More</Trans>
                </li>
              )}
            </>
          ) : (
            <div
              className="d-flex flex-column justify-content-center align-items-center no-rank-list"
              css={noRankStyle}
            >
              {sdk.customNoRanksIcon && <div className="no-ranks-icon-container mb-4">{sdk.customNoRanksIcon}</div>}
              <h5 className="text-center w-100 px-3 label-no-ranks">
                <Trans i18nKey="no-ranks-to-sho">No Ranks to show yet!</Trans>
              </h5>
            </div>
          )}
        </ul>
      )}
    </div>
  );
};

export default RankList;

const rankListCss = (theme: ITheme) => css`
  &.rank-list-card {
    padding-bottom: 150px;
    /* border: 1px solid rgba(0, 0, 0, 0.125); */
    /* padding-top: 10px; */
    /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
  }
  &.globe {
    .list-heading {
      color: #fff !important;
    }
  }
  .list-heading {
    color: ${theme.text.primary}!important;
    font-weight: 600;
    font-size: 9pt;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .heading-border {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .no-rank-list {
    border-left: 1px solid rgba(0, 0, 0, 0.125);
    border-right: 1px solid rgba(0, 0, 0, 0.125);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .rank-list-container {
    border: none;
    box-shadow: 1px 10px 20px 4px ${lighten(0.7, theme.button.primaryShadow)};
    background: transparent;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;

    &.masked {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
        top: 0;
        bottom: 0;
        border-radius: 10px;
        background-size: cover;
        filter: blur(5px);
      }
    }

    .list-group-item {
      padding-bottom: 5px;
      border: 1px solid rgba(0, 0, 0, 0.125);
    }

    .list-group-item:last-child {
      border-bottom: none;
      border: 1px solid rgba(0, 0, 0, 0.125);
    }

    .li {
      border-top: none;
      border-left: none;
      border-right: none;
      border: 1px solid rgba(0, 0, 0, 0.125);
    }

    .li:first-of-type {
      padding-top: 10px;
    }

    .li:last-child {
      padding-bottom: 10px;
    }

    .background-style {
      background-color: ${theme.foreground.primary};
      color: ${theme.text.primary};
      font-size: 13px;
      line-height: 16px;
      @media screen and (max-width: 360px) {
        font-size: 11px;
      }
    }
    @media (max-width: 1021px) {
      padding-bottom: 110px;
    }

    .label-no-ranks {
      color: black;
    }
  }

  .rank-list-container-first {
    border: none;
    box-shadow: 0 7px 20px 3px ${lighten(0.7, theme.button.primaryShadow)};
    background: transparent;
    border-radius: 10px;
    font-weight: 600;
    border: 1px solid rgba(0, 0, 0, 0.125);

    &.masked {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
        top: 0;
        bottom: 0;
        border-radius: 10px;
        background-size: cover;
        filter: blur(5px);
      }
    }
    .list-group-item {
      border-top: none;
      border-left: none;
      border-right: none;
    }

    .li {
      border-top: none;
    }

    .list-group-item:last-child {
      border-bottom: none;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    .background-style {
      background-color: ${theme.foreground.primary};
      color: ${theme.text.primary};
      font-size: 13px;
      line-height: 16px;
      @media screen and (max-width: 360px) {
        font-size: 11px;
      }
    }
  }

  .name-style {
    padding-left: 30px;
  }

  &.triangle {
    .list-heading {
      color: ${theme.text.primary};
    }
    .rank-list-container-first {
      box-shadow: none;
    }
  }
`;

const showMoreStyle = (theme: ITheme) => css`
  color: ${theme.text.primary};
  cursor: pointer;
  font-size: 18px;
  margin-bottom: 6%;
`;

const noRankStyle = (theme: ITheme) => css`
  min-height: 335px;
  color: ${theme.layout === "TRIANGLE" ? theme.button.buttonTextColor : theme.text.primary};
`;

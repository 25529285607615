import { isInProductionMode } from "actions/api/utils";
import React, { memo, useEffect } from "react";
import { Helmet } from "react-helmet";
import { isStringEqual } from "utils";
import FAVICON_BUBBALOO from "assets/clients/bubbaloo/favicon.ico";
import FAVICON_GO3 from "assets/clients/go3/favicon.ico";
import FAVICON_GO48 from "assets/clients/go48/favicon.ico";
import FAVICON_BONOXS from "assets/clients/bonoxs/favicon.ico";
import {
  isKFC,
  isBubbaloo,
  isBkash,
  isPoxipol,
  isWave,
  isGo3,
  isOoredoo,
  isDana,
  isGo48,
  isBonoxs,
} from "utils/applicationSlug";
// import { useAppSelector } from "redux/Store";

const HelmetScriptLoader = memo(function HelmetScriptLoader({ children }: { children: React.ReactNode }) {
  // const [adEnabled, setAdEnabled] = useState<boolean>(false);
  /* const { data, tournamentAdverts, c2pGameAdvert } = useAppSelector((state: any) => state.advertisements);
  useEffect(() => {
    if (data && data.length > 0 && !!tournamentAdverts && !!c2pGameAdvert) {
      const existingScript = document.getElementById("gpt");
      if (!existingScript) {
        const gptScript = document.createElement("script");
        gptScript.src = "https://securepubads.g.doubleclick.net/tag/js/gpt.js";
        gptScript.async = true;
        gptScript.id = "gpt";
        document.head.appendChild(gptScript);
        gptScript.addEventListener("load", () => {
          console.log("Ad script loaded");
          window.googletag = window.googletag || { cmd: [] };
          // !slot && callback();
          // console.log("Logo has been loaded!");
        });
        gptScript.onload = () => {
          // console.log("loaded");
          // console.log("loaded", slot);
          // !slot && callback();
        };
      }
    }
  }, [data.length, tournamentAdverts, c2pGameAdvert, data]); */

  const loadJquery = (callback?: () => void) => {
    const existingScript = document.getElementById("jquery");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = "https://code.jquery.com/jquery-3.6.3.min.js";
      script.integrity = "sha256-pvPw+upLPUjgMXY0G+8O0xUf+/Im1MZjXxxgOcBQBXU=";
      script.crossOrigin = "anonymous";
      script.id = "jquery";
      script.async = false;
      document.body.appendChild(script);
      script.onload = () => {
        if (callback) callback();
      };
    }
    if (existingScript && callback) callback();
  };

  const loadBkashCheckout = (callback?: () => void) => {
    const existingScript = document.getElementById("bKash");
    if (!existingScript) {
      const script = document.createElement("script");
      const url = window.location.href;
      const urlObject = new URL(url);
      const hostname = urlObject.hostname;
      script.src = "";

      if (isStringEqual(hostname, "go-games.gg")) {
        script.src = "https://scripts.pay.bka.sh/versions/1.2.0-beta/checkout/bKash-checkout.js";
      } else {
        script.src = "https://scripts.sandbox.bka.sh/versions/1.2.0-beta/checkout/bKash-checkout-sandbox.js";
      }
      script.id = "bKash";
      script.async = false;
      document.body.appendChild(script);
      script.onload = () => {
        if (callback) callback();
      };
    }
    if (existingScript && callback) callback();
  };

  useEffect(() => {
    if (isBkash) {
      loadJquery(() => {
        loadBkashCheckout(() => {
          // bkach checkout script loaded
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBkash]);

  return (
    <Helmet>
      {children}
      {isBubbaloo && <link rel="icon" href={FAVICON_BUBBALOO} />}
      {isBubbaloo && <title>Bubbaloo City</title>}
      {isBubbaloo && <meta name="title" content="Bubbaloo City" />}
      {isBubbaloo && <meta name="description" content="Bubbaloo City" />}
      {isBubbaloo && <meta property="og:title" content="Bubbaloo City" />}
      {isBubbaloo && <meta property="og:description" content="Bubbaloo City" />}

      {isGo3() && <link rel="icon" href={FAVICON_GO3} />}
      {isGo3() && <title>Orange Games</title>}
      {isGo3() && <meta name="title" content="Orange Games" />}
      {isGo3() && <meta name="description" content="Orange Games" />}
      {isGo3() && <meta property="og:title" content="Orange Games" />}
      {isGo3() && <meta property="og:description" content="Orange Games" />}

      {isGo48() && <link rel="icon" href={FAVICON_GO48} />}
      {isGo48() && <title>Go48 Games</title>}
      {isGo48() && <meta name="title" content="Go48 Games" />}
      {isGo48() && <meta name="description" content="Go48 Games" />}
      {isGo48() && <meta property="og:title" content="Go48 Games" />}
      {isGo48() && <meta property="og:description" content="Go48 Games" />}

      {isBonoxs && <link rel="icon" href={FAVICON_BONOXS} />}
      {isBonoxs && <title>Bonoxs Games</title>}
      {isBonoxs && <meta name="title" content="Bonoxs Games" />}
      {isBonoxs && <meta name="description" content="Bonoxs Games" />}
      {isBonoxs && <meta property="og:title" content="Bonoxs Games" />}
      {isBonoxs && <meta property="og:description" content="Bonoxs Games" />}
      {/* {isDefault && <link rel="icon" href="/favicon.ico" />} */}
      {/*  {isBkash && (
          <script
            src="https://code.jquery.com/jquery-3.6.3.min.js"
            integrity="sha256-pvPw+upLPUjgMXY0G+8O0xUf+/Im1MZjXxxgOcBQBXU="
            crossOrigin="anonymous"
            async={false}
          ></script>
        )}
        {(isBkash && isInProductionMode() && (
          <script
            defer
            src="https://scripts.pay.bka.sh/versions/1.2.0-beta/checkout/bKash-checkout.js"
            async={false}
          ></script>
        )) || (
          <script
            src="https://scripts.sandbox.bka.sh/versions/1.2.0-beta/checkout/bKash-checkout-sandbox.js"
            async={false}
            defer
          ></script>
        )} */}
      {/* Google Tag Manager */}
      {isBubbaloo && isInProductionMode() && (
        <script type="text/javascript">
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-KHN6CZB');`}
        </script>
      )}
      {/* Facebook Meta Pixel */}
      {isBubbaloo && isInProductionMode() && (
        <script type="text/javascript">
          {`!function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window,document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '825556832313768'); 
              fbq('track', 'PageView');`}
        </script>
      )}
      {/* TIKTOK Pixel */}
      {isBubbaloo && isInProductionMode() && (
        <script type="text/javascript">
          {`!function (w, d, t) { 
              w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
              ttq.load('CJDSDARC77U57QLBAU2G');
              ttq.page();
              }(window, document, 'ttq');`}
        </script>
      )}
      {/* Google Tag Manager */}
      {isBubbaloo && isInProductionMode() && (
        <noscript>
          {`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KHN6CZB" height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
        </noscript>
      )}
      {/* Facebook Meta Pixel */}
      {isBubbaloo && isInProductionMode() && (
        <noscript>
          {`<img height="1" width="1" src="https://www.facebook.com/tr?id=825556832313768&ev=PageView&noscript=1"/>`}
        </noscript>
      )}
      {isKFC && (
        <script type="text/javascript">
          {`
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '508565384368657');
              fbq('track', 'PageView');
            `}
        </script>
      )}

      {isKFC && (
        <noscript>
          {`
                <img
                alt=""
                height="1"
                width="1"
                style={{
                  display: "none"
                }}
                src="https://www.facebook.com/tr?id=508565384368657&ev=PageView&noscript=1"
              />
              `}
        </noscript>
      )}

      {/* Start Google Tag Manager for Poxipol*/}
      {isPoxipol && isInProductionMode() && (
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-KQST34LT');`}
        </script>
      )}
      {isPoxipol && isInProductionMode() && (
        <noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KQST34LT" height="0" width="0" style="display:none;visibility:hidden"></iframe>`}</noscript>
      )}
      {/* End Google Tag Manager for Poxipol*/}

      {isWave && isInProductionMode() && (
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-KV82S6L4');`}
        </script>
      )}

      {isWave && isInProductionMode() && (
        <noscript>
          {`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KV82S6L4"height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
        </noscript>
      )}

      {(isOoredoo || isDana) && isInProductionMode() && (
        <script>
          {`(function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:1611370,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
        </script>
      )}
    </Helmet>
  );
});
export default HelmetScriptLoader;

import { css } from "@emotion/react";

const foodPandaStyles = (theme: ITheme) => css`
  &.foodpandaapp {
    .header-style {
      background-color: #fff !important;
      &.title-md {
        background-color: transparent !important;
      }
    }
    .not-login-logo {
      padding-top: 95px !important;
    }

    .btn-style {
      background-color: white;
      /* box-shadow: 0 0 4px 4px ${theme.button.primaryShadow}; */
      box-shadow: 0 0 0 2px ${theme.button.primaryGradientStart};

      .label-sty {
        color: black !important;
      }
    }

    .icon-style {
      color: black !important;
    }

    .custom-popup {
      .title-text {
        color: black !important;
      }
    }

    .text-fp {
      color: black !important;
    }

    .half-style {
      .name-style,
      .score,
      .label-style {
        font-size: 11px;
      }
    }

    .show-all-text {
      color: black !important;
    }

    .payment-btn {
      border-radius: 8px !important;
      font-weight: 600;
      padding: 14px 45px;
      font-size: 14px;
    }

    .cancel-btn {
      background: white !important;
      border: 1px solid ${theme.button.primaryGradientStart} !important;
      color: ${theme.button.primaryGradientStart}!important;
    }

    .close {
      color: ${theme.button.primaryGradientStart}!important;
      fill: ${theme.button.primaryGradientStart}!important;
    }

    .mob-close {
      fill: ${theme.button.primaryGradientStart}!important;
    }

    .gamename-style {
      color: black !important;
    }

    .rank-btn {
      border: 1px solid ${theme.button.primaryGradientStart} !important;
      svg {
        fill: ${theme.button.primaryGradientStart} !important;
      }
    }

    .competitor-name,
    .competitor-score,
    .own-score {
      color: black !important;
    }

    .btn-link {
      color: ${theme.button.primaryGradientStart} !important;
    }

    .play-icon {
      fill: white !important;
    }

    .subtitle-text {
      color: black !important;
    }

    .title {
      font-size: 18px !important;
      font-weight: 600 !important;
      &-lg {
        font-size: 24px !important;
      }
      &-md {
        font-size: 18px !important;
      }
      &-sm {
        font-size: 16px !important;
        @media (min-width: 320px) and (max-width: 374px) {
          font-size: 14px !important;
        }
      }
      &-xs {
        font-size: 14px !important;
      }
    }

    .subtitle {
      font-size: 16px !important;
      &-md {
        font-size: 16px !important;
      }
      &-sm {
        font-size: 14px !important;
      }
      &-xs {
        font-size: 12px !important;
      }
    }

    .body {
      font-size: 14px !important;
      &-md {
        font-size: 14px !important;
        @media (min-width: 320px) and (max-width: 374px) {
          font-size: 12px !important;
        }
      }
      &-sm {
        font-size: 12px !important;
        @media (min-width: 320px) and (max-width: 374px) {
          font-size: 10px !important;
        }
      }
      &-xs {
        font-size: 10px !important;
      }
    }

    .header-style {
      background-color: white !important;
    }
  }
`;

export default foodPandaStyles;

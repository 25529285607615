import { css } from "@emotion/react";

const trendiStyles = (theme: ITheme) => css`
  &.trendi {
    .custom-popup {
      .title-text {
        color: #d4f200 !important;
      }
    }
    .leaderboard-popup-item {
      .show-more {
        color: #000000 !important;
      }
    }
    .card-win-prizes {
      padding-bottom: 7% !important;
      .card-banner-image {
        width: 100% !important;
        height: auto !important;
      }
      .desktop-view {
        .card-banner-image {
          width: auto !important;
          height: auto !important;
        }
      }
    }
    .cta-link-modal {
      background-color: #000000;
      .modal-header {
        background-color: #000000;
      }
    }
    .desktop-sidebar {
      .sidebar-container a:not(.active-link) .link-img {
        fill: #d4f200 !important;
        color: #d4f200 !important;
      }
    }
    .single-game-upper-div {
      .rank-button {
        color: #000000 !important;
      }
    }
    .play-button,
    .triangle-button,
    .btn-sign-up,
    .sub-button,
    .btn-footer,
    .list-heading > *,
    .play-btn-browse-container > button,
    .btn-login {
      color: #000000 !important;
      svg {
        fill: #000000 !important;
      }
    }
    .player-rank-board {
      &.desktop {
        .own-avatar-container {
          img:first-of-type,
          .own-avatar-img {
            @media (min-width: 900px) {
              width: 140px;
              height: 140px;
            }
          }
        }
      }
    }
    .progress-share-overlay {
      .half-style {
        background: #000000 !important;
      }
      .score-body {
        .name-style {
          color: #222222 !important;
        }
      }
      .btn-container {
        svg {
          fill: #222222 !important;
        }
      }
    }
    .rank-list-card {
      .list-heading > * {
        color: #fff !important;
      }
    }
    .list-heading {
      border-top-left-radius: 8px !important;
      border-top-right-radius: 8px !important;
    }
    .login-popup-modal {
      .btn-sign-up,
      .btn-login {
        background: ${theme.button.primaryGradientStart};
      }
      .title {
        color: #fff !important;
      }
      .background {
        background: #000000 !important;
      }
      &.desktop-modal {
        .background {
          background: transparent !important;
        }
        .loginpopup-desktop-background {
          fill: #000000 !important;
        }
      }
      &.mobile-modal {
        .background {
          background: #000000 !important;
        }
      }
    }
    .sign-up,
    .login-page {
      input {
        background: aliceblue !important;
      }
    }
    .desktop-footer {
      .footer-content {
        border-top: 1px solid #d4f200;
        background-color: #000000ed;
      }
      .link-img {
        fill: #ffffff !important;
      }
      .link-label {
        color: #ffffff !important;
      }
      .active-link {
        .link-img {
          fill: #d4f200 !important;
        }
        .link-label {
          color: #d4f200 !important;
        }
      }
    }
    .profile-history-list .list-group-item-text,
    .pvp-stats .label-font,
    .pvp-stats .value-font {
      color: #222222 !important;
    }

    .play-btn-browse-container {
      .play-btn-browse {
        color: #f52b85 !important;
      }
    }

    .profile-history-list {
      .header-row,
      .back-ground {
        background-color: #f2f5fe !important;
      }
      .header-text {
        color: #8a8a8a !important;
      }
    }
    .browse-game-item {
      .rank-btn {
        background: #000000 !important;
      }
      .play-btn {
        background: #000000 !important;
        box-shadow: 0 0 0 3px #44d62c !important;
      }
    }
    .achievement-history-list-item {
      .text-style-transaction,
      .avatar-name {
        color: #0c0c0c !important;
      }
    }
    .tournament-info-popup {
      .header-line,
      .competitor-name,
      .competitor-score,
      .own-score {
        color: #ffffff !important;
      }
    }
    .tournament-history-page {
      .header-style {
        color: #ffffff !important;
      }
    }
    .leaderboard-popup-item,
    .tournament-info-popup,
    .tournament-list-popup,
    .custom-popup,
    .achievement-popup {
      border-top: 1px solid #ffffff;
    }
    .tournament-list-popup .close,
    .achievement-popup .close,
    .tournament-info-popup .close,
    .leaderboard-popup-item .close {
      fill: #ffffff !important;
    }

    .leaderboard-popup-item .drag-handle:after,
    .achievement-popup .drag-handle:after,
    .tournament-info-popup .drag-handle:after,
    .custom-popup .drag-handle:after,
    .tournament-list-popup .drag-handle:after {
      background-color: #919191 !important;
    }

    .top-tournament-card .game-name,
    .top-tournament-card .btn,
    .top-tournament-card .participant,
    .top-tournament-card .prize,
    .top-tournament-card .rank,
    .top-battles-card .game-name,
    .top-battles-card .count,
    .trending-game-carousel .played-count,
    .browse-game-item .rank-btn,
    .browse-game-item .play-btn,
    .game-card .played-count,
    .leaderboard-popup-item .gamename-style,
    .user-achievements .title,
    .goama-copyright-text,
    .tournament-list-popup .contain-style > span {
      color: #ffffff !important;
      svg {
        fill: #fff !important;
      }
    }
    .tournament-info .entry-fee h5,
    .tournament-info .title-sub,
    .leaderboard-popup-item .entry-fee,
    .leaderboard-popup-item .fontStyle,
    .browse-game-item .game-name,
    .browse-game-item .label-price,
    .prize-calculator-container .fontStyle,
    .profile-history-list .list-group-item-text,
    .pvp-stats .label-font,
    .pvp-stats .value-font {
      color: #222222 !important;
    }

    .card-body {
      color: black;
    }

    .see-prizes {
      color: black !important;
    }

    .title-xs {
      color: black !important;
    }
  }
`;

export default trendiStyles;

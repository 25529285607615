import React from "react";
import { css } from "@emotion/react";
import modalLogo from "assets/clients/Ooredoo/ooredoo_logo.png";
import { ReactComponent as TickIcon } from "assets/images/subscription/tick_icon.svg";
import CommonSubscriptionModal from "../CommonSubscriptionModal";
import { useAppDispatch, useAppSelector } from "redux/Store";
import useModalHandler from "hooks/useModalHandler";
import { fetchSubscriptionUserInfo } from "redux/reducers/subscriptionReducer";
import { Trans } from "react-i18next";

const UnsubscribeSuccessfulModalStyle = () => css`
  .modal-body-icon {
    height: 95px;
    width: 95px;
    border-radius: 50%;
    padding: 20px;
    background-color: #ed1c24;
    box-shadow: 1px 3px 29px 0 rgba(150, 150, 150, 0.21);
    g {
      path {
        fill: white !important;
      }
    }
  }
`;

export default function UnsubscribeSuccessfulModal() {
  const dispatch = useAppDispatch();
  const { package_info } = useAppSelector((state: any) => state.subscription);
  const { showUnsubscribeSuccessModal } = useAppSelector((state: any) => state.modal);
  const { closeUnsubscribeSuccessModal, closeSubscriptionModal } = useModalHandler();

  const handleUnsubscribeModalHide = () => {
    dispatch(fetchSubscriptionUserInfo(package_info?.slug));
    closeUnsubscribeSuccessModal();
    closeSubscriptionModal();
  };

  return (
    <CommonSubscriptionModal
      show={showUnsubscribeSuccessModal}
      onHide={handleUnsubscribeModalHide}
      title={<Trans i18nKey="unsubscribe-su">Unsubscribe Successful</Trans>}
      logo={modalLogo}
      footer={
        <button onClick={handleUnsubscribeModalHide}>
          <Trans i18nKey="ok">Ok</Trans>
        </button>
      }
      style={UnsubscribeSuccessfulModalStyle}
    >
      <div className="modal-body-container">
        <TickIcon className="modal-body-icon" />
      </div>
    </CommonSubscriptionModal>
  );
}

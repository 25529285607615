import MemepopScoreSummary from "../../../src/components/clients/Memepop/MemepopScoreSummary";

export const memepopSDK: ISdkResource = {
  type: "built-in",
  goBackHome: () => {},
  getMemepopScoreSummary: () => {
    return <MemepopScoreSummary />;
  },
};

export default memepopSDK;

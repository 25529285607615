import { css } from "@emotion/react";

const metaversegoStyles = css`
  &.metaversego {
    .page-login,
    .authentication {
      .navbar-wrapper {
        position: fixed;
        top: 0;
        left: 5px;
      }
      background-image: linear-gradient(0deg, #160323 0%, #23042a 53%);
      .icon-container {
        background: #ffffff;
      }
      .input-wrapper {
        border-radius: 10px;
        background: #d9d9d9;
        .input-group-addon {
          font-weight: bold;
          color: #020202;
        }
        input {
          font-size: small;
          background: #0ed4c9;
          color: #ffffff;
        }
        input::placeholder {
          color: #909090; /* Color of the placeholder text */
          font-style: italic; /* Style of the text (optional) */
          font-weight: 600; /* Weight of the font (optional) */
          /* Other styling properties can also be added as needed */
        }
      }
      .sub-button {
        color: #ffffff;
        background: #0ed4c9;
      }
    }
    .confirm-modal-wrapper {
      .sub-button {
        color: #ffffff;
        background: #0ed4c9;
      }
    }
    [data-rsbs-scroll] {
      background-image: linear-gradient(0deg, #160323 0%, #23042a 53%);
      > * {
        color: #ffffff !important;
      }
    }
    .tournament-history-page {
      position: absolute;
      z-index: 5;
      width: 100%;
      .history-container {
        position: relative;
        z-index: 2;
      }
      .white-card {
        border: 1px solid #0ed4c9;
      }
    }
    .profile-page {
      background: transparent;
      .profile-history-list {
        position: relative;
        z-index: 5;
      }
      .show-all-text {
        color: #ffffff;
      }
      .date-style {
        font-size: 12px;
      }
      .desk-subbutton {
        background-color: #0ed4c9 !important;
        color: #ffffff !important;
      }
    }
    .profile-history-wrapper {
      .back-ground {
        color: #ffffff;
      }
      .history-border {
        border-top-right-radius: 0 !important;
        border-top-left-radius: 0 !important;
      }
      .list-group-item:first-of-type {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }
    .cta-link-modal {
      .modal-header {
        margin-bottom: 0 !important;
        .modal-title {
          visibility: hidden;
        }
      }
    }
    .tournament-info-popup {
      * {
        color: #ffffff;
      }
    }
    .progress-share-overlay {
      .card-win-prizes {
        background: transparent !important;
      }
      .modal-content {
        height: 100%;
      }
      * {
        color: #ffffff !important;
      }
      .half-style {
        background: #170424 !important;
        box-shadow: 0 5px 5px 0 #0ed4c9;
      }
      background-image: linear-gradient(180deg, #6d094f 17%, #170424 100%);

      .btn-circle-with-icon {
        box-shadow: none;
        background: #0ed4c9;
      }
      .recommended-games-container {
        display: none;
      }
    }
    .coming-soon-text {
      color: #ffffff !important;
    }
    body {
      background-image: linear-gradient(180deg, #6d094f 17%, #170424 100%);
    }
    .leaderboard-popup-item {
      background-image: transparent;
      .modal_header {
        background-color: #23042a;
      }
      .lower-div {
        .icon {
          background: #ffffff !important;
        }
      }
      .prize-list-container {
        * {
          color: #ffffff !important;
        }
      }
      .desktop-play-footer,
      .btn-tap-to-see > .motion-button > .btn-style {
        background: #f8a03d !important;
        color: #ffffff !important;
        box-shadow: none !important;
      }
      .rank-list-card {
        .rank-list-container-first,
        .rank-list-container {
          * {
            color: #ffffff !important;
          }
        }
      }
    }
    .navbar-home {
      position: relative;
      display: none !important;
    }
    .upperdiv,
    .background-sty {
      background-color: #170424;
    }
    .single-game-upper-div {
      .btn-login {
        background-color: #f8a03d;
        border-radius: 15px;
        padding: 7%;
        color: #ffffff;
        width: 80px;
        height: 90%;
        outline: none !important;
        font-weight: bold;
      }
      .upperdiv {
        /* max-height: 50vh; */
        box-shadow: 0 5px 5px 0 #0ed4c9;
        > div:first-of-type {
          padding-top: 0 !important;
        }
        .game-name {
          margin-left: 0 !important;
          .name {
            color: #ffffff !important;
            padding-left: 0 !important;
            width: 100%;
            text-align: center;
            font-size: 2rem;
          }
        }
        .logo-sty {
          padding-top: 5px;
          img {
            margin-bottom: 0 !important;
            margin-top: 0 !important;
          }
        }
      }
      .btn-see-prizes-wrapper {
        display: none !important;
      }
      .thumbnail {
        width: 80%;
      }
      .play-button {
        background-color: #f8a03d;
        box-shadow: 0 2px 5px 0 #f8a03d;
      }
      .rank-button {
        background-color: #0ed4c9;
        box-shadow: 0 2px 5px 0 #0ed4c9;
      }
      .rank-button,
      .play-button {
        color: #ffffff !important;
        font-weight: bolder !important;
      }
    }
    .tournament-games-page {
      min-height: auto;
      margin-top: 2rem !important;
      &.not-login {
        padding-top: 0% !important;
      }
      .game-page-inner {
        padding-bottom: 3rem;
        z-index: 2;
        position: relative;
        margin-bottom: 0;
      }
    }
    .footer-wrapper {
      width: 100%;
      img {
        max-height: 50px;
      }
      position: relative;
      z-index: 1;
      bottom: 5px;
      .mobile-footer-page {
        padding-bottom: 0;
      }
      .desktop-footer {
        display: none;
      }
    }
    .leaderboard-popup-footer {
      bottom: 8.313rem;
    }
  }
`;

export default metaversegoStyles;

import { css } from "@emotion/react";
import NOIR from "assets/fonts/Noir/Noir_regular.otf";

const gcashStyles = (theme: ITheme) => css`
  @font-face {
    font-family: "Noir";
    src: local("Noir"), url(${NOIR}) format("opentype");
  }

  &.gcashbpo {
    .right-style {
      .upper {
        background-color: #fbff14 !important;
      }
    }
    .show-all-text {
      color: #222222 !important;
    }
    .tournament-info-popup {
      .header-line,
      .competitor-name,
      .competitor-score,
      .own-score {
        color: #222222 !important;
      }
    }
  }

  &.gcash {
    .body,
    * {
      font-family: "Noir";
    }
    #root > .header-style {
      /* background: ${theme.button.primaryGradientStart} !important; */
    }
    .navbar-top-logo-2 img {
      max-width: 165px !important;
    }
    .profile-pvp-stats {
      .pvp-stats {
        box-shadow: 0 0 35px 30px rgb(0 0 0 / 5%) !important;
      }
    }
    .user-profile-wrapper {
      .fontSubStyle {
        color: ${theme.button.primaryGradientStart} !important;
      }
      .title-md {
        color: ${theme.button.primaryGradientStart} !important;
      }
    }
    .tournament-games-page {
      /* padding-top: 90px; */
    }
    .navbar-home {
      .header-style {
        background: ${theme.button.primaryGradientStart} !important;
      }
    }

    .play-btn-browse-container {
      .play-btn-browse {
        .body-md {
          font-weight: bold !important;
          color: #f52b85 !important;
          span {
            font-weight: bold !important;
            color: #f52b85 !important;
          }
        }
      }
    }

    .half-style {
      background: linear-gradient(0deg, rgba(255, 221, 236, 1) 0%, rgba(255, 94, 162, 1) 100%);
      .latest-score-label {
        color: #231f20 !important;
      }
      .board-container {
        .tile {
          fill: ${theme.foreground.primary} !important;
        }
        .score-body {
          color: ${theme.button.primaryGradientStart} !important;
        }
        .label-style {
          background: ${theme.button.primaryGradientStart} !important;
        }
        .name-style {
          color: ${theme.button.primaryGradientStart} !important;
        }
      }
      .second-half {
        .btn-container {
          border: 0 !important;
        }
      }
    }
    .right-style {
      .upper {
        background-color: ${theme.button.primaryGradientStart} !important;
      }
    }
    .browse-popup,
    .tournament-info-popup {
      background: ${theme.foreground.primary} !important;
    }
    .white-card {
      box-shadow: 0 0 35px 30px rgb(0 0 0 / 5%);
    }
    .profile-history-list {
      .header-text {
        color: ${theme.foreground.primary} !important;
      }
    }
    .tournament-list-popup {
      .small-card {
        background: ${theme.button.primaryGradientStart} !important;
        .play-btn {
          background: ${theme.foreground.primary} !important;
          .play-icon {
            fill: ${theme.button.primaryGradientStart} !important;
          }
        }
        span {
          color: ${theme.foreground.primary} !important;
        }
        svg {
          fill: ${theme.foreground.primary};
        }
      }
    }
    .tournament-info-popup {
      .avatar1,
      .avatar {
        border: 1px solid ${theme.button.primaryGradientStart};
      }
    }
    .profile-pvp-stats {
      svg {
        fill: ${theme.button.primaryGradientStart};
      }
    }
    .tournament-list-item {
      background: ${theme.button.primaryGradientStart} !important;
      .play-btn-browse {
        background: ${theme.foreground.primary} !important;
        .play-icon {
          fill: ${theme.button.primaryGradientStart} !important;
        }
      }
      .rank-btn {
        .rank-icon {
          fill: ${theme.button.primaryGradientStart} !important;
        }
      }
      .name-sty {
        color: ${theme.foreground.primary} !important;
        svg {
          fill: ${theme.foreground.primary} !important;
        }
      }
    }
    .desktop-footer {
      .link-label {
        font-weight: 500;
      }
    }
    .board-container {
      .score-body {
        .title-md {
          color: ${theme.background.primary} !important;
        }
      }
    }
    .show-all-text {
      color: ${theme.text.primary} !important;
    }
    .tournament-info-popup {
      .header-line,
      .competitor-name,
      .competitor-score,
      .own-score {
        color: ${theme.text.primary} !important;
      }
    }
    .card-prize {
      .div-group {
        color: ${theme.text.primary} !important;
      }
    }

    .list-group-item {
      color: ${theme.text.primary} !important;
    }

    .colorStyle,
    .value-font,
    .text-progress,
    .clock-icon,
    .text-timer {
      color: ${theme.text.primary} !important;
    }

    .label-font {
      color: ${theme.text.primary} !important;
    }

    .prizes-slider-container {
      .prizes-slider-item {
        .title-md {
          color: ${theme.text.primary} !important;
        }
      }
    }

    .title-md,
    .show-all-text,
    .header-title,
    .sub-header,
    .play-count {
      color: ${theme.text.primary} !important;
    }

    /* .icon(
        fill: ${theme.text.primary} !important;
      ) */
    .text-style-transaction {
      color: ${theme.text.primary} !important;
    }

    .show-more,
    .avatarStyle {
      color: ${theme.text.primary} !important;
    }
    .ticket-status {
      .label-sty {
        color: ${theme.background.primary};
      }
      .desktop-plus {
        svg,
        .icon-style {
          fill: ${theme.foreground.primary};
          color: ${theme.foreground.primary} !important;
        }
      }
    }
    .rank-list-container {
      .list-group-item {
        /* border: 0 !important; */
      }
      .li:first-of-type {
        padding-top: 5px !important;
      }
    }
    .rank-list-container-first {
      background-color: transparent !important;
      .list-heading {
        margin-bottom: 0 !important;
      }
      .list-group-item:last-child {
        border-top-right-radius: 0 !important;
        border-top-left-radius: 0 !important;
      }
    }
    .my-games-page {
      .title-md,
      .page-title {
        color: ${theme.foreground.primary} !important;
      }
      .game-name,
      .body-xs {
        color: ${theme.background.primary};
      }
      .profile-icon,
      .timer-icon {
        fill: ${theme.button.primaryGradientStart};
      }
    }
    .desktop-footer {
      .footer-content {
        opacity: 0.85;
      }
      .active-link {
        .link-label {
          color: ${theme.button.primaryGradientStart} !important;
        }

        .link-img {
          color: ${theme.button.primaryGradientStart};
          fill: ${theme.button.primaryGradientStart};
        }
      }
      .link-label {
        /* color: ${theme.background.tertiary}; */
      }
      .link-img {
        /* color: ${theme.background.tertiary}; */
        /* fill: ${theme.background.tertiary}; */
      }
    }
    .reward-label {
      color: ${theme.foreground.primary};
    }
    .desk-sublabel {
      color: ${theme.foreground.primary};
    }
    .game-of-the-month {
      .header-title,
      .sub-header,
      .play-count {
        color: ${theme.foreground.primary} !important;
      }
      svg {
        fill: ${theme.foreground.primary} !important;
      }
    }
    .navbar-home {
      .ticket-status {
        background-color: ${theme.foreground.primary} !important;
        span,
        .icon-style {
          color: ${theme.button.primaryGradientStart} !important;
        }
      }
    }

    .button-group {
      .group-btn {
        .ticket-enabled-coin-enabled {
          .token-status {
            background-color: white !important;

            .label-sty {
              color: #0079ff !important;
            }

            .icon-style {
              color: #0079ff !important;
            }
          }
        }
      }
    }

    .tournament-list-popup {
      .small-card {
        span {
          color: #000000 !important;
        }
      }
    }

    .card-border {
      background-color: #0332b2 !important;
      border: 2px solid white;
    }

    .add-token-container {
      background-color: #0332b2 !important;
    }

    .draggable-modal {
      [data-rsbs-overlay] {
        background: #0332b2 !important;
      }
    }

    .single-token {
      svg {
        g {
          path {
            fill: #ffffff !important;
          }

          g {
            fill: #0079ff !important;
          }
        }
      }

      .value {
        color: #0079ff !important;
        font-weight: 600 !important;
        padding-top: 20px !important;
      }

      .price {
        color: #ffffff !important;
      }

      .label {
        color: #ffffff !important;
        font-size: 15px !important;
      }
    }

    .button-cashout[name="submit"] {
      margin-right: 10px;
    }

    .div-image {
      visibility: hidden !important;
    }

    .leaderboard-popup-item {
      .modal_header {
        background-color: #0332b2;
      }

      .gamename-style,
      .reward-sty {
        color: #ffffff !important;
      }
    }

    .text-sty-label {
      color: #ffffff !important;
    }
  }
`;

export default gcashStyles;

import { useBedrockPassport } from "@bedrock_org/passport";

export const useSafeBedrockPassport = () => {
  try {
    // Try using the useBedrockPassport hook
    return useBedrockPassport();
  } catch (error) {
    // If the provider is not loaded, return fallback values
    console.warn("BedrockPassportProvider is not loaded:", error);
    return {
      user: null, // Safe fallback
      isLoggedIn: false,
      signIn: () => console.warn("BedrockPassportProvider is not loaded."),
      loginCallback: () => console.warn("BedrockPassportProvider is not loaded."),
      signOut: () => console.warn("BedrockPassportProvider is not loaded."),
    };
  }
};
